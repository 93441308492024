import React from 'react'
import Button from '@material-ui/core/Button'

import ConfirmationDialog from '../ConfirmationDialog'
import "./style.css";

/**
 * Component displaying the contents of the user panel in Partner > View
 */
const UserList = ({ users, removeUser, partnerName }) => 
    users.map(user => 
        <User
            key={user.id}
            user={user}
            removeUser={() => removeUser(user.id)}
            partnerName={partnerName}/>
        )

/**
 * Individual list user components in the useer panel
 */
const User = ({ user, removeUser, partnerName }) => {
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)

    const handleConfirm = () => {
        removeUser()
        setConfirmationDialogOpen(false)
    }
    
    return (
        <div className="user-row-container">
            <div className="user-row">
                <div className="user-column user-column-left">
                    <div>
                        <p className="user-name">
                            {`${user.firstName} ${user.lastName}`}{!user.initialized && ` (Pending)`}
                        </p>
                        <h5>{user.email}</h5>
                    </div>
                </div>
                <div className="user-column user-column-middle">
                    <h5>{user.role.split('_')[1]}</h5>
                </div>
                <div className="user-column user-column-right">
                    <Button onClick={() => setConfirmationDialogOpen(true)} color="secondary">{'Remove'}</Button>
                </div>
            </div>
            <ConfirmationDialog
                open={confirmationDialogOpen}
                handleClose={() => setConfirmationDialogOpen(false)}
                handleConfirm={handleConfirm}
                title={`Delete ${user.firstName} ${user.lastName} from ${partnerName}?`}/>
        </div>
    )
}

export default UserList