/**
 * @module item
 */

import moment from "moment";

export const TYPE_NORMAL = "TYPE_NORMAL";
export const TYPE_BOOLEAN = "TYPE_BOOLEAN";
export const TYPE_DATE = "TYPE_DATE";
export const TYPE_DATE_RELATIVE = "TYPE_DATE_RELATIVE";
export const TYPE_CURRENCY = "TYPE_CURRENCY";
export const TYPE_REDEEMED = "TYPE_REDEEMED";

const toDate = date => moment(date, moment.ISO_8601, true);

/**
 * Infer the type if a
 *
 * @param {string} value
 * @returns {string} TYPE constant
 */
function inferType(value) {
	if (value === undefined || value === null) {
		return TYPE_NORMAL;
	}

	if (typeof value === "boolean") {
		return TYPE_BOOLEAN;
	}

	if (toDate(value).isValid()) {
		return TYPE_DATE;
	}

	return TYPE_NORMAL;
}

/**
 * Convert value from raw value to a user friendly readable format
 * for display on page. Mostly used on tables.
 *
 * @param {string} value
 * @param {object} [options]
 * @param {string} [options.type]
 * @returns {string}
 */
function convertToUserFriendlyString(value, options = {}) {
	const type = options.type || inferType(value);

	switch (type) {
		case TYPE_CURRENCY:
			return `$${value / 100}`;
		case TYPE_NORMAL:
			return value;
		case TYPE_BOOLEAN:
			return value ? "True" : "False";
		case TYPE_DATE:
		case TYPE_DATE_RELATIVE: {
			const date = toDate(value);

			if (type === TYPE_DATE_RELATIVE) {
				return date.fromNow();
			}

			return date.format("MMM DD, YYYY");
		}
		case TYPE_REDEEMED: {
			return value ? 'Yes' : 'No'
		}
		default:
			return value;
	}
}

/**
 * Get property of object by string
 * Supports (dot seperated) nested properties
 *
 * @example
 * // returns 'Example name',
 * getObjectProperty({ user: { name: 'Example name' } }, 'user.name')
 *
 * @param {object} object
 * @param {string} property - property path with dot seperated nested values
 * @returns {*} property value
 */
function getObjectProperty(object, property) {
	const keys = property.split(".");
	return keys.reduce((prev, curr) => (prev ? prev[curr] : ""), object);
}

/**
 * Transforms string of object properties to string of string of properties values
 *
 * @example
 * // returns 'Bobby Tables'
 * getObjectProperties({ name: { first: 'Bobby', last: 'Tables' } }, 'name.first name.last');
 *
 * @param {object} object
 * @param {string} string
 * @returns string
 */
function getObjectProperties(object, string, options = {}) {
	const keys = string.split(" ");

	const values = keys.map(key => {
		const propertyString = getObjectProperty(object, key.trim());

		return options.userFriendly
			? convertToUserFriendlyString(propertyString, { type: options.type })
			: propertyString;
	});

	// If there is only a single value return it without joining to preserve it's type
	return values.length === 1 ? values[0] : values.join(" ");
}

/**
 * Set property of object by string
 * Supports (dot seperated) nested properties
 * Does not mutate the object
 *
 * @example
 * // returns { address: '123', name: { first: 'Bobby' } }
 * setObjectProperty({ address: '123' }, 'name.first', 'Bobby')
 *
 * @param {object} object
 * @param {string} property - property path with dot seperated nested values
 * @param {*} value - value to set at property path
 * @returns {object}
 */
function setObjectProperty(object, property, value) {
	const keys = property.split(".");
	const lastKey = keys.pop();
	const cloned = JSON.parse(JSON.stringify(object));

	const pointer = keys.reduce((acc, key) => {
		if (acc[key] === undefined) {
			acc[key] = {};
		}

		return acc[key];
	}, cloned);

	pointer[lastKey] = value;
	return cloned;
}

export default {
	convertToUserFriendlyString,
	getObjectProperty,
	getObjectProperties,
	setObjectProperty
};
