export const resultValidationError = result => {
	// Return custom error if program name fails regex validation
	if (
		result.error.details &&
		result.error.details[0].type === "string.regex.base"
	) {
		return "Program Name cannot contain white space";
	} else {
		// Return joi error message for other validation errors
		return result.error.details[0].message;
	}
};

export const responseValidationError = response => {
	if (response.message) {
		return response.message.split("):").pop();
	}
	// Optionally an error message returned in response could be used
	else if (typeof response.error === "string") {
		return response.error;
	} else {
		return "Something went wrong while trying to submit your form. Please try again.";
	}
};

export const isEmailAddressValid = (email) => /^[^@]+@[^@]{2,}\.[^@]{2,}$/.test(email)