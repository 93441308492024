import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./style.css";

class Header extends React.Component {
	render() {
		return (
			<nav className="site-header passive container--full">
				<span>
					<NavLink exact to="/" className="logo-wrapper">
						<img className="logo" src={this.props.logo} alt="Rachio logo" />
					</NavLink>
				</span>

				<span>
					<p onClick={this.props.onLogout} key={"signout-link"}>
						{'Sign Out'}
					</p>
				</span>
			</nav>
		);
	}
}

Header.propTypes = {
	onLogout: PropTypes.func.isRequired
};

export default Header;
