import React from "react";
import Helmet from "react-helmet";
import { Route, withRouter } from "react-router";
import { connect } from "react-redux";

import LayoutPortal from "../../Portal";
import { setAuth, logoutUser } from "../../../store/actions/authentication";
import titleLib from "../../../lib/title";
import Dashboard from "../../../pages/Dashboard";

class PortalLayout extends React.Component {
	constructor(props) {
		super(props);

		this.onUnauthorized = this.onUnauthorized.bind(this);
		this.onAuthorized = this.onAuthorized.bind(this);
		this.onLogout = this.onLogout.bind(this);
	}

	onUnauthorized() {
		this.props.history.push("/");
	}

	onAuthorized(auth) {
		this.props.dispatch(setAuth(JSON.parse(auth)));
	}

	async onLogout() {
		await this.props.dispatch(logoutUser());
		this.props.history.push("/");
	}

	render() {
		const { component: Component, isAdmin, adminRoute, ...rest } = this.props;

		return (
			<Route
				{...rest}
				render={matchProps => (
					<React.Fragment>
						<Helmet>
							{/* Title is overrideable via helmet in each page file */}
							<title>{titleLib.titleForPage(window.location.pathname)}</title>
						</Helmet>

						<LayoutPortal
							logo={"/img/rachio-logo-white.png"}
							subnavLinks={{
								Dashboard: "/admin",
								...isAdmin ? { Partners: "/admin/partners" } : null,
								Programs: "/admin/programs",
								Residents: "/admin/residents",
								...isAdmin ? { Devices: "/admin/devices" } : null
							}}
							onUnauthorized={this.onUnauthorized}
							onAuthorized={this.onAuthorized}
							onLogout={this.onLogout}
							component={isAdmin || !adminRoute ? Component : Dashboard}
							matchProps={matchProps}
							isAdmin={this.props.isAdmin}
						/>
					</React.Fragment>
				)}
			/>
		);
	}
}


function mapStateToProps(state) {
	const { auth } = state.authentication;

	return {
		isAdmin: !!auth && auth.role === 'ADMIN' 
	};
}
export default connect(mapStateToProps)(withRouter(PortalLayout));
