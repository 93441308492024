import React from 'react'
import qs from "query-string"
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import MultiSelect from "../../../components/MultiSelect"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Filter from "../../../components/Filter";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  }
}))

function ControlledExpansionPanel(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(true)

  const handleClear = (e) => {
    e.stopPropagation()
    const query = qs.parse(window.location.search)
    delete query.partners
    delete query.programs
        
		props.history.push({search: qs.stringify({ ...query})})
        props.clearFilter()
        props.fetchResidents()
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded}
        style={{ boxShadow: 'none', width: '100%'}}
        onChange={() => setExpanded(!expanded)}>
        <ExpansionPanelSummary
          aria-controls="panel-content"
          id="panel-header">
            <Typography className={classes.heading}>{expanded
            ? <ExpandLess style={{paddingTop: 12}}/>
            : <ExpandMore style={{paddingTop: 12}}/>
            }{'Filter'}</Typography>
            {(Boolean(props.selectedPartners.length) || Boolean(props.selectedPrograms.length))
              && <Button
                  size={'small'}
                  color={'primary'}
                  onClick={handleClear}
                  style={{ maxHeight: '48px' }}>{'Clear'}</Button>}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
            <div style={{ width: '100%' }}>
                {props.partners.length > 1 &&
                    <MultiSelectHOC
                    label={'Partners'}
                    items={props.partners.map(partner => partner.name).sort()}
                    selectedItems={props.selectedPartners}
                    setSelectedItems={items => {
                        props.updateQuery({ partners: [...items] })
                        props.setSelectedItems({selectedPartners: items})
                    }}/>}
                {props.programs.length > 1 &&
                    <MultiSelectHOC
                    label={'Programs'}
                    items={props.programs // if partner(s) selected via filter, only show programs from partner(s)
                      .map(program => ({ name: program.name, muni: program.muniName}))
                      .filter(program => props.selectedPartners.length === 0 || props.selectedPartners.includes(program.muni))
                      .map(p => p.name).sort()}
                    selectedItems={props.selectedPrograms}
                    setSelectedItems={items => {
                        props.updateQuery({ programs: [...items] })
                        props.setSelectedItems({selectedPrograms: items})
                    }}/>}
                    <Filter style={{ marginTop: '12px'}} onChange={props.onSearchChange} />
            </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      
    </div>
  )
}

const ExpansionPanelSummary = withStyles({
    root: {
        padding: 0,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        '&$expanded': {
            margin: 0
        }
    },
    expanded: {},
  })(MuiExpansionPanelSummary)

function MultiSelectHOC(props) {
  const matches = useMediaQuery('(min-width:600px)')
	const useStyles = makeStyles(() => ({
		formControl: {
            width: `${matches ? 'calc(50% - 8px)' : '100%'}`,
            margin: '0px 4px'
		}
	}))
	const classes = useStyles()

	return (
		<MultiSelect classes={classes} {...props} />
	)
}

export default ControlledExpansionPanel
