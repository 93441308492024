import React from "react";
import PanelLabeled from "../../../components/PanelLabeled";

import CSVUpload from "../../../components/CSVUpload";
// import DeleteButton from "../../../components/DeleteButton";
import SerialCSVUpload from "../../../components/SerialCSVUpload";
import DevicesToResidents from "../../../components/DevicesToResidents";
import { ItemUpdateLayout } from "../../../components/ItemLayouts";
import { currencyFormatter } from "../../../utils/currency";
import { deleteProgram, getProgram, updateProgram } from "../../../lib/api";
import { programStatus } from "../../../constants";
import {
	Form,
	FieldSet,
	Input,
	Checkbox,
	Select
} from "../../../components/Form";

// Helper/warning text for input fields
const warningField = {
	distribution_limit:
		"This affects the countdown on the landing page and when the program goes to waitlist",
	flow_meter_included:
		"This fundamentally alters the appearance and functionality of the landing page",
	hellosign_key:
		"If using HelloSign, provide name of file uploaded to AWS bucket",
	max_sprinkler_zones_allowed:
		"This directly affect options for the zone dropdown",
	program_city: "This affects user claim functionality",
	program_state: "This affects user claim functionality",
	requirements:
		"These options fundamentally alter the appearance and functionality of the landing page",
	shipping_id: "A Shipping ID must exist for controllers to be shipped",
	slug:
		"This must match EXACTLY the slug for the program's landing page (e.g. for 'go.rachio.com/lvmwd' this should be 'lvmwd')",
	status: "Warning: Changes to status affect program website"
};

class ProgramUpdatePage extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);

		this.state = {
			program: null,
		};
	}

	async componentDidMount() {
		const program = await getProgram(this.props.match.params.programId);

		this.setState({
			program
		});
	}

	async onSubmit({ values }) {
		const price = Number(values.price.replace(/[^0-9.-]+/g, "")) * 100;
		const accountNumberUploadResponse = await this.accountNumberUploadForm.upload();
		
		const serialNumberUploadResponse = await this.serialNumberUploadForm.upload();

		const devicesToResidentsUploadResponse = await this.devicesToResidents.upload();

		if (
			devicesToResidentsUploadResponse instanceof Error ||
			devicesToResidentsUploadResponse.code
		) {
			return { error: true, message: devicesToResidentsUploadResponse.message };
		}

		if (accountNumberUploadResponse && (accountNumberUploadResponse instanceof Error || accountNumberUploadResponse.code)) {
			return { error: true, message: accountNumberUploadResponse.message };
		}

		if (
			serialNumberUploadResponse instanceof Error ||
			serialNumberUploadResponse.code
		) {
			return { error: true, message: serialNumberUploadResponse.message };
		}

		return updateProgram({
			...values,
			price,
			id: this.state.program.id,
			auditRequirement: !!values.auditRequirement,
			installationRequirement: !!values.installationRequirement
		});
	}

	async handleDelete() {
		await deleteProgram(this.state.program.id);
		this.props.history.push("/admin/programs");
	}

	render() {
		const { program } = this.state;

		if (!program) return null;

		return (
			<ItemUpdateLayout itemType="Program" itemName={program.name}>
				{/* Removing as backend functionality not in place at this time */}
				{/* <DeleteButton
					style={{
						float: "right",
						padding: "6px"
					}}
					handleDelete={this.handleDelete}
					type={"Program"}
					item={program.name ? program.name : "this program"}
				/> */}
				<Form
					onSubmit={this.onSubmit}
					buttonText="Update"
					buttonLoadingText="Updating..."
					successMessage="Program successfully updated"
				>
					<div className="split">
						<div className="split__item">
							<PanelLabeled label="Information">
								<Input
									label="Partner ID"
									placeholder="Partner ID"
									name="id"
									type="text"
									defaultValue={program.muniId}
									disabled
								/>

								<Input
									label="Program Name"
									name="name"
									type="text"
									defaultValue={program.name}
								/>
								<Input
									label="Program City"
									name="programCity"
									type="text"
									defaultValue={program.programCity}
									warningtext={warningField.program_city}
								/>
								<Input
									label="Program State"
									name="programState"
									type="text"
									defaultValue={program.programState}
									warningtext={warningField.program_state}
								/>
								<Input
									label="Program Phone Number"
									placeholder="3035551234"
									name="programPhoneNumber"
									type="text"
									defaultValue={program.programPhoneNumber}
								/>
								<Input
									label="Shipping ID"
									name="shippingId"
									type="text"
									defaultValue={program.shippingId}
									warningtext={warningField.shipping_id}
								/>
								<Input
									label="HelloSign AWS Object Key"
									name="hellosignAwsObjectKey"
									type="text"
									defaultValue={program.hellosignAwsObjectKey}
									warningtext={warningField.hellosign_key}
								/>

								<Select
									label="Status"
									name="status"
									defaultValue={program.status}
									warningtext={warningField.status}
									required
								>
									<option value="" disabled>
										Select
									</option>
									{Object.keys(programStatus).map(key => (
										<option key={key} value={key}>
											{programStatus[key]}
										</option>
									))}
								</Select>

								<Input
									label="Distribution Limit"
									name="distributionLimit"
									type="number"
									defaultValue={program.distributionLimit}
									warningtext={warningField.distribution_limit}
								/>
								<Input
									label="Max Sprinkler Zones Allowed"
									name="maxSprinklerZonesAllowed"
									type="number"
									defaultValue={program.maxSprinklerZonesAllowed}
									warningtext={warningField.max_sprinkler_zones_allowed}
								/>
								<Input
									label="Budget"
									name="budget"
									type="number"
									defaultValue={program.budget}
								/>
								<Input
									label="Price"
									name="price"
									type="text"
									defaultValue={currencyFormatter.format(program.price / 100)}
								/>
								<Input
									label="Charge Statement Descriptor"
									name="chargeStatementDescriptor"
									type="text"
									defaultValue={program.chargeStatementDescriptor}
								/>
								<Input
									label="Slug"
									name="slug"
									type="text"
									defaultValue={program.slug}
									warningtext={warningField.slug}
								/>
								<Checkbox
									label="Flow Meter Included"
									name="flowMeterIncluded"
									defaultChecked={program.flowMeterIncluded}
									warningtext={warningField.flow_meter_included}
								/>
								{/* Placeholder for outdoor enclosure functionality */}
								{/* <Checkbox
									label="Include Outdoor Enclosure"
									name="includeOutdoorEnclosure"
									defaultChecked={program.includeOutdoorEnclosure}
								/>
								<Input
									label="Outdoor Enclosure Cost"
									name="outdoorEnclosureCost"
									type="text"
									defaultValue={currencyFormatter.format((program.outdoorEnclosureCost || 0) / 100)}
								/> */}
								<Input
									label="Terms Of Agreement"
									name="termsLink"
									type="text"
									defaultValue={program.termsLink}
								/>
							</PanelLabeled>
						</div>
						<div className="split__item">
							<PanelLabeled label="Requirements">
								<FieldSet
									legend="Installation Process"
									warningtext={warningField.requirements}
								>
									<Checkbox
										label="Pro Installation"
										name="installationRequirement"
										defaultChecked={program.installationRequirement}
									/>
									<Checkbox
										label="Scheduling Required"
										name="schedulingRequired"
										defaultChecked={program.schedulingRequired}
									/>
									<Checkbox
										label="Audit"
										name="auditRequirement"
										defaultChecked={program.auditRequirement}
									/>
									<Checkbox
										label="HelloSign Required"
										name="hellosignRequired"
										defaultChecked={program.hellosignRequired}
									/>
								</FieldSet>
							</PanelLabeled>

							<PanelLabeled label="Eligible Account Numbers">
								<CSVUpload
									ref={accountNumberUploadForm => {
										this.accountNumberUploadForm = accountNumberUploadForm;
									}}
									programId={program.id}
								/>
							</PanelLabeled>

							<PanelLabeled label="Serial Numbers">
								<SerialCSVUpload
									ref={serialNumberUploadForm => {
										this.serialNumberUploadForm = serialNumberUploadForm;
									}}
									programId={program.id}
								/>
							</PanelLabeled>

							<PanelLabeled label="Link Residents">
								<DevicesToResidents
									ref={devicesToResidents => {
										this.devicesToResidents = devicesToResidents;
									}}
									programId={program.id}
								/>
							</PanelLabeled>
						</div>
					</div>
				</Form>
			</ItemUpdateLayout>
		);
	}
}

export default ProgramUpdatePage;
