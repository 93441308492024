import React from "react";
import { ItemCreateLayout } from "../../../components/ItemLayouts";
import PanelLabeled from "../../../components/PanelLabeled";
import {
	Form,
	FieldSet,
	Input,
	TextArea,
	Checkbox,
	Select
} from "../../../components/Form";

import { getPrograms, createResident } from "../../../lib/api";

class ResidentCreatePage extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			programs: []
		};
	}

	async componentDidMount() {
		const programs = await getPrograms();

		this.setState({
			programs
		});
	}

	async onSubmit({ values }) {
		const resident = await createResident(values);

		if (resident.error) {
			return resident;
		}

		this.setState({
			residentId: resident.id
		});

		return null;
	}

	render() {
		const { programs } = this.state;

		if (!programs.length) return null;

		return (
			<ItemCreateLayout itemType="Resident">
				<Form
					onSubmit={this.onSubmit}
					buttonText="Create"
					buttonLoadingText="Creating..."
					successMessage="Resident successfully created"
				>
					<div className="split">
						<div className="split__item">
							<PanelLabeled label="Information">
								<div className="flex">
									<div className="flex__item">
										<Input
											label="First Name"
											name="firstName"
											type="text"
											placeholder="First Name"
										/>
									</div>
									<div className="flex__item">
										<Input
											label="Last Name"
											name="lastName"
											type="text"
											placeholder="Last Name"
										/>
									</div>
								</div>

								<Input
									label="Account Number"
									name="accountNumber"
									type="text"
									placeholder="Account Number"
								/>

								<Input
									label="Email Primary"
									name="emailPrimary"
									type="text"
									placeholder="Email Primary"
								/>

								<Input
									label="Phone Number"
									name="phone"
									type="text"
									placeholder="Phone Number"
								/>
							</PanelLabeled>

							<PanelLabeled label="Property">
								<div className="flex">
									<div className="flex__item">
										<Input
											label="Address 1"
											name="address.addressLine1"
											type="text"
											placeholder="Address 1"
										/>
									</div>
									<div className="flex__item">
										<Input
											label="Address 2"
											name="address.addressLine2"
											type="text"
											placeholder="Address 2"
										/>
									</div>
								</div>

								<div className="flex">
									<div className="flex__item">
										<Input
											label="City"
											name="address.city"
											type="text"
											placeholder="City"
										/>
									</div>
									<div className="flex__item">
										<Input
											label="State"
											name="address.region.code"
											type="text"
											placeholder="State"
										/>
									</div>
								</div>

								<Input
									label="Zip Code"
									name="address.postal_code"
									type="text"
									placeholder="Zip Code"
								/>
							</PanelLabeled>

							<PanelLabeled label="Program">
								<Select
									label="Program"
									name="programId"
									defaultValue={programs[0].id}
								>
									{programs.map(program => (
										<option key={program.id} value={program.id}>
											{program.name}
										</option>
									))}
								</Select>

								<FieldSet legend="Redemption Status">
									<Checkbox
										label="Redeemed"
										name="redeemed"
										defaultChecked={false}
									/>
								</FieldSet>

								<Input
									label="Signed Terms"
									name="signedTermsLink"
									type="text"
									placeholder="Signed Terms"
								/>
							</PanelLabeled>

							<PanelLabeled label="Internal Notes">
								<TextArea label="Notes" name="notes" />
							</PanelLabeled>
						</div>
					</div>
				</Form>
			</ItemCreateLayout>
		);
	}
}

export default ResidentCreatePage;
