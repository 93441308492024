// @flow

/*
 * Analytics Tracker
 * @module tracker/analytics
 *
 * Tracks page views with Google Analytics
 *
 * Original code from https://github.com/react-ga/react-ga/issues/122#issuecomment-353101102
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import ReactGA from 'react-ga';

let hasGABeenInitialized: boolean = false;

class AnalyticsTracker extends React.Component<any> {
	componentDidMount() {
		if (process.env.NODE_ENV === 'production' && !hasGABeenInitialized) {
			ReactGA.initialize(this.props.GAToken);
			hasGABeenInitialized = true;
		}

		this.sendPageChange(this.props.location.pathname + this.props.location.search);
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname
			|| this.props.location.search !== prevProps.location.search) {
			this.sendPageChange(this.props.location.pathname + this.props.location.search);
		}
	}

	sendPageChange(page) {
		ReactGA.set({ page });
		ReactGA.pageview(page);
	}

	render() {
		return null;
	}
}

AnalyticsTracker.propTypes = {
	GAToken: PropTypes.string.isRequired,
};

export default () => <Route component={AnalyticsTracker} />;
