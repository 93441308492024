import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import textLib from "../../utils/text";
import BannerHeader from "../BannerHeader";

const ViewBanner = props => (
	<BannerHeader
		headerText={props.itemName}
		itemType={props.itemType}
		showReturnButton
		showEditButton
		showDeleteButton={!!props.deleteHandler}
		deleteHandler={props.deleteHandler}
		{...props}
	/>
);

const UpdateBanner = props => (
	<BannerHeader
		headerText={`Edit ${props.itemName}`}
		itemType={props.itemType}
		showReturnButton
		{...props}
	/>
);

const CreateBanner = props => (
	<BannerHeader
		headerText={`Create ${props.itemType}`}
		showReturnButton
		{...props}
	/>
);

const ListBanner = props => {
	const itemCount = props.itemCount || (props.items && props.items.length) || 0;

	return (
		<BannerHeader
			headerText={`${itemCount} ${textLib.pluralize(
				props.itemType,
				itemCount,
				props.itemTypeEndsInSibilant
			)}`}
			itemType={props.itemType}
			showCreateButton={props.showCreateButton}
			{...props}
		/>
	);
};

const layoutToBannerMap = {
	view: ViewBanner,
	update: UpdateBanner,
	create: CreateBanner,
	list: ListBanner
};

const ItemLayout = props => {
	const Banner = layoutToBannerMap[props.layout];

	return (
		<React.Fragment>
			<div className="block__container banner__container">
				<div className="block--sm container--full">
					<Banner {...props} />
				</div>
			</div>

			<div className="block--md container--full">
				<div className={props.layout === "list" ? "panel" : ""}>
					{props.children}
				</div>
			</div>
		</React.Fragment>
	);
};

ItemLayout.propTypes = {
	layout: PropTypes.oneOf(["list", "view", "create", "update"]).isRequired,

	// Partner, Program, etc...
	itemType: PropTypes.string.isRequired,
	// Plural suffix is -es instead of -s
	itemTypeEndsInSibilant: PropTypes.bool,
	itemName: PropTypes.string,
	itemCount: PropTypes.number,
	showCreateButton: PropTypes.bool,
	deleteHandler: PropTypes.func
};

ItemLayout.defaultProps = {
	itemTypeEndsInSibilant: false,
	itemName: null,
	itemCount: undefined,
	showCreateButton: true,
	deleteHandler: undefined
};

export const ItemListLayout = props => <ItemLayout layout="list" {...props} />;
export const ItemCreateLayout = props => (
	<ItemLayout layout="create" {...props} />
);
export const ItemUpdateLayout = props => (
	<ItemLayout layout="update" {...props} />
);
export const ItemViewLayout = props => <ItemLayout layout="view" {...props} />;
