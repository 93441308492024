import React from "react";
import { ItemUpdateLayout } from "../../../components/ItemLayouts";
import PanelLabeled from "../../../components/PanelLabeled";
import { withRouter } from "react-router-dom";

import {
	Form,
	FieldSet,
	Input,
	TextArea,
	Select,
	Checkbox
} from "../../../components/Form";

import { getResident, updateResident, deleteResident } from "../../../lib/api";
import DeleteButton from "../../../components/DeleteButton";

class ResidentUpdatePage extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);

		this.state = {
			resident: null
		};
	}

	async componentDidMount() {
		const resident = await getResident(this.props.match.params.residentId);

		this.setState({
			resident
		});
	}

	async onSubmit({ values }) {
		return updateResident({
			...values,
			id: this.state.resident.id
		});
	}

	async handleDelete() {
		const { programId, accountNumber } = this.state.resident;
		await deleteResident(this.state.resident.id, {
			residents: [{ programId, accountNumber }]
		});
		this.props.history.push("/admin/residents");
	}

	render() {
		const { resident } = this.state;

		if (!resident) return null;

		return (
			<ItemUpdateLayout itemType="Resident" itemName={resident.emailPrimary}>
				<Form
					onSubmit={this.onSubmit}
					buttonText="Update"
					buttonLoadingText="Updating..."
					successMessage="Resident successfully updated"
				>
					<div>
						<div>
							<DeleteButton
								style={{
									float: "right",
									padding: "6px"
								}}
								handleDelete={this.handleDelete}
								type={"Resident"}
								item={resident.firstName ? resident.firstName : "this resident"}
							/>
							<PanelLabeled label="Information">
								<Input
									label="Resident ID"
									placeholder="Resident ID"
									name="id"
									type="text"
									defaultValue={resident.id}
									disabled
								/>

								<div className="flex">
									<div className="flex__item">
										<Input
											label="First Name"
											placeholder="First Name"
											name="firstName"
											type="text"
											defaultValue={resident.firstName}
										/>
									</div>
									<div className="flex__item">
										<Input
											label="Last Name"
											placeholder="Last Name"
											name="lastName"
											type="text"
											defaultValue={resident.lastName}
										/>
									</div>
								</div>
								<Input
									label="Account Number"
									placeholder="Account Number"
									name="accountNumber"
									type="text"
									defaultValue={resident.accountNumber}
									required
								/>

								<Input
									label="Email"
									placeholder="Email"
									name="emailPrimary"
									type="text"
									defaultValue={resident.emailPrimary}
								/>

								<Input
									label="Secondary Email"
									placeholder="Secondary Email"
									name="emailSecondary"
									type="text"
									defaultValue={resident.emailSecondary}
								/>

								<Input
									label="Phone Number"
									placeholder="Phone Number"
									name="phone"
									type="text"
									defaultValue={resident.phone}
								/>
							</PanelLabeled>

							<PanelLabeled label="Property">
								<div className="flex">
									<div className="flex__item">
										<Input
											label="Address 1"
											placeholder="Address 1"
											name="address.addressLine1"
											type="text"
											defaultValue={
												resident.address && resident.address.addressLine1
											}
										/>
									</div>
									<div className="flex__item">
										<Input
											label="Address 2"
											placeholder="Address 2"
											name="address.addressLine2"
											type="text"
											defaultValue={
												resident.address && resident.address.addressLine2
											}
										/>
									</div>
								</div>

								<div className="flex">
									<div className="flex__item">
										<Input
											label="City"
											placeholder="City"
											name="address.city"
											type="text"
											defaultValue={resident.address && resident.address.city}
										/>
									</div>
									<div className="flex__item">
										<Input
											label="State"
											placeholder="State"
											name="address.region.code"
											type="text"
											defaultValue={
												resident.address &&
												resident.address.region &&
												resident.address.region.code
											}
										/>
									</div>
									<div className="flex__item">
										<Input
											label="Country"
											placeholder="Country"
											name="address.country.name"
											type="text"
											defaultValue={
												resident.address &&
												resident.address.country &&
												resident.address.country.name
											}
										/>
									</div>
								</div>

								<Input
									label="Zip Code"
									placeholder="Zip Code"
									name="address.postalCode"
									type="text"
									defaultValue={resident.address && resident.address.postalCode}
								/>

								<Select
									label="Sprinkler Zones"
									name="sprinklerZones"
									defaultValue={resident.sprinklerZones}
								>
									{!resident.sprinklerZones ? (
										<option value="" disabled>
											-
										</option>
									) : null}
									<option value="8">8</option>
									<option value="16">16</option>
								</Select>

								{/* <Select
									label="Install Location"
									name="installLocation"
									defaultValue={resident.installLocation.installLocation}
								>
									{!resident.installLocation.installLocation ? (
										<option value="" disabled>
											-
										</option>
									) : null}
									<option value="INDOORS">Indoors</option>
									<option value="OUTDOORS">Outdoors</option>
								</Select>

								<Select
									label="Install Type"
									name="installationType"
									defaultValue={resident.installationType.installationType}
								>
									{!resident.installationType.installationType ? (
										<option value="" disabled>
											-
										</option>
									) : null}
									<option value="DIY">DIY</option>
									<option value="PRO">PRO</option>
								</Select> */}
							</PanelLabeled>

							<PanelLabeled label="Program">
								<FieldSet legend="Redemption Status">
									<Checkbox
										label="Redeemed"
										name="redeemed"
										defaultChecked={resident.redeemed}
									/>
								</FieldSet>

								<FieldSet legend="Audit Passed">
									<Checkbox
										label="Audit Passed"
										name="auditPassed"
										defaultChecked={resident.auditPassed}
									/>
								</FieldSet>

								<Input
									label="Signed Terms"
									placeholder="Signed Terms"
									name="signedTermsLink"
									type="text"
									defaultValue={resident.signedTermsLink}
								/>

								<Input
									label="Schedule Once Status"
									placeholder="Schedule Once Status"
									name="scheduleOnceStatus"
									type="text"
									defaultValue={resident.scheduleOnceStatus}
								/>

								<Input
									label="Schedule Once ID"
									placeholder="Schedule Once ID"
									name="scheduleOnceId"
									type="text"
									defaultValue={resident.scheduleOnceId}
								/>
							</PanelLabeled>

							<PanelLabeled label="Internal Notes">
								<TextArea
									label="Notes"
									name="notes"
									defaultValue={resident.notes}
								/>
							</PanelLabeled>
						</div>
					</div>
				</Form>
			</ItemUpdateLayout>
		);
	}
}

export default withRouter(ResidentUpdatePage);
