import React from "react";

import ItemList from "../../../components/ItemList";
import { ItemListLayout } from "../../../components/ItemLayouts";
import Switch from "../../../components/Switch";
import { getPrograms, getPartners } from "../../../lib/api";

class ProgramsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			programs: null,
			enabledPrograms: null,
			showAll: false
		};
	}

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		const programsData = await getPrograms();

		const programs = this.addRevenueToPrograms(programsData);
		const enabledPrograms = await this.getEnabledPrograms(programs);

		this.setState({
			programs,
			enabledPrograms
		});
	}

	toggleShowAll() {
		this.setState({ showAll: !this.state.showAll });
	}

	// hides programs from disabled partners/muni's
	getEnabledPrograms = async programs => {
		const partners = await getPartners();

		const disabledPartners = partners
			.filter(partner => !partner.enabled)
			.map(partner => partner.id);

		return programs.filter(
			program =>
				!disabledPartners.includes(program.muniId) &&
				program.status !== "ARCHIVED"
		);
	};

	// adds revenue to programs from (program price * units redeemed)
	addRevenueToPrograms = programs =>
		programs.map(program => ({
			revenue: program.price * program.residentsRedeemedCount,
			...program
		}));

	render() {
		const { programs, enabledPrograms, showAll } = this.state;
		return (
			<ItemListLayout
				isAdmin={this.props.isAdmin}
				itemType="Program"
				itemCount={
					programs && (showAll ? programs.length : enabledPrograms.length)
				}
			>
				<Switch toggleShowAll={() => this.toggleShowAll()} />
				<ItemList
					isAdmin={this.props.isAdmin}
					itemType="Program"
					items={showAll ? programs : enabledPrograms}
					table={{
						Program: "name",
						...(this.props.isAdmin) && {"Revenue-noSort": { type: "TYPE_CURRENCY", field: "revenue" }},
						Status: "status",
						"Muni Name": "muniName",
						Budget: "budget",
						Limit: "distributionLimit",
						Residents: "residentsCount",
						Redeemed: "residentsRedeemedCount",
						Checked: "residentsWithEmailCount",
						"Controllers Left": "unitsLeftCount",
						Price: { type: "TYPE_CURRENCY", field: "price" },
						"Program City": "programCity",
						"Program State": "programState",
						"Program Phone": "programPhoneNumber",
						"Flow Meter Included": "flowMeterIncluded",
						Slug: "slug",
						"Audit Requirement": "auditRequirement",
						"Installation Requirement": "installationRequirement",
						"Charge Statement Descriptor": "chargeStatementDescriptor",
						"Terms Link": "termsLink",
						"Scheduling Required": "schedulingRequired",
						"Shipping ID": "shippingId",
						Created: "created",
						Updated: "updated",
						"Max Sprinkler Zones Allowed": "maxSprinklerZonesAllowed",
						"HelloSign Required": "hellosignRequired",
						"HelloSign AWS Object Key": "hellosignAwsObjectKey",
						"Muni ID": "muniId",
						id: "id"
					}}
				/>
			</ItemListLayout>
		);
	}
}

export default ProgramsPage;
