import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import { FieldContainer } from '../Form'
import { isEmailAddressValid } from '../../utils/validation'
import { addMuniUser } from '../../lib/api'

// Roles
const PORTAL_USER = 'portal_user'
const PORTAL_ADMIN = 'portal_admin'

// Component
const AddUser = ({partnerId, listUsers}) => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [helpText, setHelpText] = useState('')
    const [lastName, setLastName] = useState('')
    const [role, setRole] = useState(PORTAL_USER)
    const [isLoading, setIsLoading] = useState(false)

    /**
     * Reset the form after muni user successfully added
     */
    const resetForm = () => {
        setEmail('')
        setFirstName('')
        setLastName('')
        setHelpText('')
        setRole(PORTAL_USER)
        setIsLoading(false)
    }

    /**
     * Handles adding user, resetting the form, fetching the list of users and any errors that occur
     */
    const addUser = () => {
        const body = { 
            partnerId,
            firstName,
            lastName,
            email,
            role
         }
        setIsLoading(true)
        addMuniUser(body).then(res => {
            resetForm()
            listUsers()
            if (res.code) {
                setHelpText(`Error: ${res.message.split('. ')[1]}`)
            }
        }).catch(e => {
            // eslint-disable-next-line no-console
            console.error(e)
            setHelpText('An Error Occured')
            setIsLoading(false)
        })

    }

    /**
     * Checks to see if form is valid, simple email and name validation, sets help text if not valid
     */
    const isFormValid = () => {
        switch (false) {
            case isEmailAddressValid(email):
                setHelpText('Email invalid')
                break
            case firstName.length > 0:
                setHelpText('First name required')
                break
            case lastName.length > 0:
                setHelpText('Last name required')
                break
            default:
                return true
        }
    }

    /**
     * Handles the user clicking the add user button
     */
    const handleAddUser = () => {
        setHelpText('')
        if (isFormValid()) {
            addUser()
        }
    }

    return (
        <FieldContainer helpText={helpText} label={'Add User'}>
            <div className="flex">
                <div className="flex__item">
                    <UserInfoInput
                        placeholder={'email'}
                        value={email}
                        onChange={setEmail} />
                </div>
                <div className="flex__item">
                    <UserInfoInput
                        placeholder={'first name'}
                        value={firstName}
                        onChange={setFirstName} />
                </div>
                <div className="flex__item">
                    <UserInfoInput
                        placeholder={'last name'}
                        value={lastName}
                        onChange={setLastName} />
                </div>
                <div className="flex__item">
                    <select onChange={(e) => setRole(e.target.value)} value={role}>
                        <option value={PORTAL_USER}>{'User'}</option>
                        <option value={PORTAL_ADMIN}>{'Admin'}</option>
                    </select>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    color={'primary'}
                    onClick={handleAddUser}
                    disabled={isLoading}>
                        {!isLoading ? 'ADD' : 'ADDING'}
                </Button>
            </div>
        </FieldContainer>
    )
}

/**
 * Input Components for the Shared Access Add user Panel in Partner > View
 */
const UserInfoInput = ({
    placeholder,
    value,
    onChange
}) => (
        <input
            type={'text'}
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)} />
    )

export default AddUser