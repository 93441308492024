import React from "react";
import { ItemViewLayout } from "../../../components/ItemLayouts";
import ItemView from "../../../components/ItemView";

import { getProgram } from "../../../lib/api";
import StatsBar from "../../../components/StatsBar";
import { currencyFormatter } from "../../../utils/currency";

class ProgramsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			program: null
		};
	}

	async componentDidMount() {
		const program = await getProgram(this.props.match.params.programId);

		this.setState({
			program
		});
	}

	// changes program price from cents to dollars
	adjustPriceFormat(program) {
		const dollars = program.price / 100;
		return Object.assign({}, program, {
			price: currencyFormatter.format(dollars)
		});
	}

	render() {
		const { program } = this.state;

		if (!program) return null;

		return (
			<ItemViewLayout itemType="Program" itemName={program.name}>
				<div className="block--sm">
					<div className="panel">
						<StatsBar
							stats={[
								{
									text: "Eligible Accounts",
									stat: program.residentsCount
								},
								{
									text: "Residents Redeemed",
									stat: program.residentsRedeemedCount
								},
								{
									text: "Controllers Left",
									stat: program.unitsLeftCount
								}
							]}
						/>
					</div>
				</div>

				<ItemView
					item={this.adjustPriceFormat(program)}
					tables={[
						[
							"Information",
							{
								"Program ID": "id",
								"Program City": "programCity",
								"Program State": "programState",
								"Program Phone": "programPhoneNumber",
								"Muni ID": "muniId",
								Name: "name",
								Price: "price",
								"Distribution Limit": "distributionLimit",
								Budget: "budget",
								Created: "created",
								Updated: "updated",
								Status: "status",
								Residents: "residentsCount",
								"Residents Redeemed Count": "residentsRedeemedCount",
								Checked: "residentsWithEmailCount",
								"Units Left Count": "unitsLeftCount",
								Slug: "slug",
								"Max Sprinkler Zones Allowed": "maxSprinklerZonesAllowed",
								"Flow Meter Included": "flowMeterIncluded",
								"Charge Statement Descriptor": "chargeStatementDescriptor",
								"Shipping ID": "shippingId",
								"HelloSign AWS Object Key": "hellosignAwsObjectKey",
								"Terms of Agreement": "termsLink"
							}
						],
						[
							"Requirements",
							{
								"Installation Requirement": "installationRequirement",
								"Audit Requirement": "auditRequirement",
								"Scheduling Required": "schedulingRequired",
								"Signature Required": "hellosignRequired"
							}
						]
					]}
				/>
			</ItemViewLayout>
		);
	}
}

export default ProgramsPage;
