import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import itemLib from "../../utils/item";
import PanelLabeled from "../../components/PanelLabeled";

function getColumnValue(item, value) {
	if (React.isValidElement(value)) {
		return value;
	}

	return itemLib.getObjectProperties(item, value, {
		userFriendly: true
	});
}

function getPanelChildren(tableMap, item) {
	// If value is a react element render it
	if (React.isValidElement(tableMap)) {
		return tableMap;
	}

	const columns = Object.keys(tableMap).reduce((acc, column) => {
		const value = getColumnValue(item, tableMap[column]);
		acc.push({
			column,
			value
		});
		return acc;
	}, []);

	if (!columns.length) return null;

	return (
		<table className="table-list table-list--vertical">
			<tbody>
				{columns.map(({ column, value }) => (
					<tr className="table-list__item" key={`${column}`}>
						<th>{column}</th>
						<td>{value}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export const Split = props => {
	let tableHeadline = "";

	return props.tables.map((value, i) => {
		// If value is string (instead of object) parse it as the headline for the next panel
		if (typeof value === "string") {
			tableHeadline = value;
			return null;
		}

		const children = getPanelChildren(value, props.item);

		if (children === null) {
			return null;
		}

		return (
			<PanelLabeled label={tableHeadline} key={`${tableHeadline}-${i}`}>
				{children}
			</PanelLabeled>
		);
	});
};

Split.propTypes = {
	// See ItemView for example values
	item: PropTypes.object.isRequired,
	tables: PropTypes.array.isRequired
};

class ItemView extends React.Component {
	render() {
		const { item, tables } = this.props;
		const split1Tables = tables[0] || [];
		const split2Tables = tables[1] || [];

		return (
			<div className="split">
				<div className="split__item">
					<Split item={item} tables={split1Tables} />
				</div>
				<div className="split__item">
					<Split item={item} tables={split2Tables} />
				</div>
			</div>
		);
	}
}

ItemView.propTypes = {
	// Object where all string values of the table are sourced from
	item: PropTypes.object.isRequired,
	// Description of table rows to be displayed
	// Example:
	// [[
	//   'Information', {
	//     'ID': 'id',
	//     'Name': 'username',
	//     'Pro': 'pro.name',
	//     'Rating': <Stars rating={review.rating} />,
	//     'Created': 'created',
	//     'Updated': 'updated',
	//   },
	// ], []]
	tables: PropTypes.array.isRequired
};

export default ItemView;
