import React from 'react'
import PropTypes from 'prop-types'

const PanelLabeled = props => (
	<div className='panel-container'>
            <h3>{props.label}</h3>
		<div className='panel' style={{...props.panelStyle}}>
			{props.children}
		</div>
	</div>
)

PanelLabeled.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.element.isRequired,
		PropTypes.arrayOf(PropTypes.element),
    ]).isRequired
}

export default PanelLabeled
