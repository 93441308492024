export const residentTable = [
	[
		"Information",
		{
			ID: "id",
			Name: "firstName lastName",
			"Account Number": "accountNumber",
			"Primary Email": "emailPrimary",
			"Secondary Email": "emailSecondary",
			Phone: "phone",
			Created: "created",
			Updated: "updated",
			"Audit Passed": "auditPassed"
		},
		"Property Information",
		{
			"Address Line 1": "address.addressLine1",
			"Address Line 2": "address.addressLine2",
			City: "address.city",
			State: "address.region.code",
			"Zip Code": "address.postalCode",
			Country: "address.country.name",
			"Sprinkler Zones": "sprinklerZones",
			"Install Location": "installLocation",
			"Installation Type": "installationType"
		},
		"Internal Notes",
		{
			Notes: "notes"
		}
	],
	[
		"Program Information",
		{
			Partner: "muniName",
			Program: "programName",
			"Program ID": "programId",
			"Redemption Status": "redeemed",
			"Redemption Date": "redemptionDate",
			"Signed Terms Link": "signedTermsLink"
		},
		"Shipping Information",
		{
			"ShipStation Order ID": "shipStationOrderId",
			"Tracking Number": "trackingNumber",
			Carrier: "carrier",
			"Shipping Status": "shippingStatus"
		},
		"Payment Information",
		{
			"Stripe Customer ID": "stripeCustomerId",
			"Stripe Payment ID": "stripePaymentId"
		},
		"Scheduling Information",
		{
			"ScheduleOnce Id": "scheduleOnceId",
			"ScheduleOnce Status": "scheduleOnceStatus"
		}
	]
];
