import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Info from "@material-ui/icons/Info";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class AlertDialogSlide extends React.Component {
	state = {
		open: false
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		return (
			<div>
				<Info
					color="primary"
					onClick={this.handleClickOpen}
					style={{ cursor: "pointer" }}
				/>
				<Dialog
					open={this.state.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">
						{this.props.titleText}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							{this.props.textContent}
						</DialogContentText>
						{this.props.content}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Got it
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default AlertDialogSlide;
