import React from "react";
import PanelLabeled from "../../../components/PanelLabeled";
import tzlookup from "tz-lookup";

import { Form, FieldSet, Input, Checkbox } from "../../../components/Form";
import DeleteButton from "../../../components/DeleteButton";
import { ItemUpdateLayout } from "../../../components/ItemLayouts";
import { deletePartner, getPartner, updatePartner } from "../../../lib/api";

class PartnerUpdatePage extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);

		this.state = {
			partner: null
		};
	}

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		const partner = await getPartner(this.props.match.params.partnerId);

		this.setState({
			partner
		});
	}

	handleLatLongChange(values) {
		if (
			values.geoPoint &&
			values.geoPoint.latitude & values.geoPoint.longitude
		) {
			const { latitude, longitude } = values.geoPoint;

			const timeZone = tzlookup(latitude, longitude);
			const geoPoint = {
				latitude: Number(latitude),
				longitude: Number(longitude)
			};
			return { ...values, timeZone, geoPoint };
		}
		return values;
	}

	async onSubmit({ values }) {
		const updateObj = this.handleLatLongChange(values);

		await updatePartner({
			...updateObj,
			id: this.state.partner.id
		});

		this.loadData();
	}

	async handleDelete() {
		await deletePartner(this.state.partner.id);
		this.props.history.push("/admin/partners");
	}

	render() {
		const { partner } = this.state;

		if (!partner) return null;

		return (
			<ItemUpdateLayout itemType="Partner" itemName={partner.name}>
				<DeleteButton
					style={{
						float: "right",
						padding: "6px"
					}}
					handleDelete={this.handleDelete}
					type={"Partner"}
					item={partner.name ? partner.name : "this partner"}
				/>
				<Form
					onSubmit={this.onSubmit}
					buttonText="Save"
					buttonLoadingText="Saving..."
					successMessage="Partner successfully updated"
				>
					<div className="split">
						<div className="split__item">
							<PanelLabeled label="Information">
								<Input
									label="Partner ID"
									placeholder="Partner ID"
									name="id"
									type="text"
									defaultValue={partner.id}
									disabled
								/>
								<Input
									label="Name"
									placeholder="Name"
									name="name"
									type="text"
									defaultValue={partner.name}
								/>

								<FieldSet legend="">
									<Checkbox
										label="Enabled"
										placeholder="Enabled"
										name="enabled"
										defaultChecked={partner.enabled}
									/>
								</FieldSet>

								<Input
									label="Time Zone"
									placeholder="Time Zone"
									name="timeZone"
									type="text"
									value={partner.timeZone}
									disabled
								/>
								<Input
									label="Zip"
									placeholder="Zip"
									name="zip"
									type="text"
									defaultValue={partner.zip}
								/>
								<Input
									label="Website"
									placeholder="Website"
									name="website"
									type="text"
									defaultValue={partner.website}
								/>
								<Input
									label="Latitude"
									placeholder="Latitude"
									name="geoPoint.latitude"
									type="text"
									defaultValue={partner.geoPoint.latitude}
									helpText={"Between -90 and 90"}
								/>
								<Input
									label="Longitude"
									placeholder="Longitude"
									name="geoPoint.longitude"
									type="text"
									defaultValue={partner.geoPoint.longitude}
									helpText={"Between -180 and 180"}
								/>
							</PanelLabeled>
						</div>
					</div>
				</Form>
			</ItemUpdateLayout>
		);
	}
}

export default PartnerUpdatePage;
