// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox
} from '@material-ui/core'

// Constants
const rachioBlue: '#009fd9' = '#009fd9'

// Props
type Props = {
    items: {[string]: string, ...},
    selectedItems: ?string[],
    setSelectedItems: (?string[]) => void,
    label: string,
    classes: {formControl: string}
}

// Component
function MultiSelect(props: Props) {
  const { items, selectedItems, setSelectedItems, classes, label } = props

  const handleChange = event => {
    setSelectedItems(event.target.value)
  }

  return (
      <FormControl className={classes.formControl}>
        <InputLabel id={'multi-select-dropdown'}>{label}</InputLabel>
        <Select
          labelId={'multi-select-dropdown'}
          id={'multi_select_' + label}
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<Input />}
          renderValue={selected => selected.map(item => item).join(', ')}>
          {items.map((item, idx) => (
            <MenuItem key={item + idx} value={item}>
              <RachioCheckbox checked={selectedItems.indexOf(item) > -1}/>
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}

// HOC for checkboc for rachio blue colored mark
const RachioCheckbox = withStyles({
  root: {
    color: 'grey',
    '&$checked': {
      color: rachioBlue,
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />)

export default MultiSelect
