import React from 'react'
import { withRouter } from 'react-router-dom'
import { resetPassword } from '../../lib/api'

const resetTypeParam = 'resetportal'

const Reset = ({ history }) => {
    const [emailAddress, setEmailAddress] = React.useState('')
    const [showConfirm, setShowConfirm] = React.useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        if (emailAddress) {
            resetPassword({ emailAddress, resetTypeParam })
            setShowConfirm(true)
        }
    }

    return (
        !showConfirm ?
            <ResetPasswordForm
                text={ `Enter your email address and we'll send a password reset link.` }
                btnText={ 'send' }
                onSubmit={ onSubmit }>
                <input
                    value={ emailAddress }
                    type={ 'text' }
                    placeholder={ 'Email' }
                    onChange={ e => setEmailAddress(e.target.value) }/>
            </ResetPasswordForm> : 
            <ResetPasswordForm
                text={ `A link to reset your password has been sent to your email.` }
                btnText={ 'ok' }
                onSubmit={ () => history.push('/') }>
            </ResetPasswordForm>
    )
}

const ResetPasswordForm = ({ text, children, onSubmit, btnText }) => (
    <form onSubmit={ onSubmit }>
        <div style={{ fontSize: 18, marginBottom: 24 }}>{ text }</div>
            { children }
            <button
                style={{ marginTop: 48 }}
                className={ `button button--lg` }>
                    { btnText }
            </button> 
    </form>
)

export default withRouter(Reset)
