import React from "react";

import "./style.css";

const Loader = () => (
	<div className="loader loader--full">
		<div className="loader__rects">
			<div className="loader__rect loader__rect--1" />
			<div className="loader__rect loader__rect--2" />
			<div className="loader__rect loader__rect--3" />
			<div className="loader__rect loader__rect--4" />
			<div className="loader__rect loader__rect--5" />
		</div>
	</div>
);

export default Loader;
