import React from "react";
import { withRouter } from 'react-router';
import { ItemCreateLayout } from "../../../components/ItemLayouts";
import PanelLabeled from "../../../components/PanelLabeled";
import {
	Form,
	FieldSet,
	Input,
	Checkbox,
	Select
} from "../../../components/Form";

import schemas from "../../../schemas";
import { programStatus } from "../../../constants";
import { getPartners, createProgram } from "../../../lib/api";

class ProgramCreatePage extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			partners: []
		};
	}

	async componentDidMount() {
		const partners = await getPartners();

		this.setState({
			partners
		});
	}

	async onSubmit({ values }) {
		return createProgram({
			...values,
			auditRequirement: !!values.auditRequirement,
			installationRequirement: !!values.installationRequirement
		});
	}

	redirectAfterSubmit = () => {
		setTimeout(() => this.props.history.push('/admin/programs/'), 500)
	}

	render() {
		const { partners } = this.state;

		if (!partners.length) return null;

		return (
			<ItemCreateLayout itemType="Program">
				<Form
					onSubmit={this.onSubmit}schema={schemas.program.create}
					postSubmitAction={this.redirectAfterSubmit}
					buttonText="Create"
					buttonLoadingText="Creating..."
					successMessage="Program successfully created"
				>

					<div className="split">
						<div className="split__item">
							<PanelLabeled label="Information">
								<Input
									label="Program Name"
									name="name"
									type="text"
									placeholder="Program Name"
								/>

								<Select
									label="Partner"
									name="muniId"
									defaultValue={partners[0].id}
								>
									{partners.map(partner => (
										<option key={partner.id} value={partner.id}>
											{partner.name}
										</option>
									))}
								</Select>

								<Select label="Status" name="status" defaultValue="" required>
									<option value="" disabled>
										Select
									</option>
									{Object.keys(programStatus).map(key => (
										<option key={key} value={key}>
											{programStatus[key]}
										</option>
									))}
								</Select>

								<Input
									label="Distribution Limit"
									name="distributionLimit"
									type="number"
									placeholder="Distribution Limit"
								/>
								<Input
									label="Budget"
									name="budget"
									type="number"
									placeholder="Budget"
								/>
								<Input
									label="Price"
									placeholder="Price"
									helpText="Value in cents"
									name="price"
									type="number"
								/>
								<Input
									label="Charge Statement Descriptor"
									placeholder="Charge Statement Descriptor"
									name="chargeStatementDescriptor"
									type="text"
								/>
							</PanelLabeled>
						</div>
						<div className="split__item">
							<PanelLabeled label="Requirements">
								<Input
									label="Terms Of Agreement"
									name="termsLink"
									type="text"
									placeholder="Terms Of Agreement"
								/>

								<FieldSet legend="Installation Process">
									<Checkbox
										label="Pro Installation"
										name="installationRequirement"
									/>
									<Checkbox label="Audit" name="auditRequirement" />
								</FieldSet>
							</PanelLabeled>
						</div>
					</div>
				</Form>
			</ItemCreateLayout>
		);
	}
}

export default withRouter(ProgramCreatePage);
