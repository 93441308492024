/**
 * Construct title tag content for standard portal pages
 * Tags can be overidden on individual pages using the react-helmet package
 *
 * @module title
 */

import urlLib from '../utils/url';

const titleSuffix = 'Rachio Utility Admin Portal';
const sectionTitleMap = {
	'': 'Sign In',
	'confirm-password': 'Confirm Password',
	'admin': 'Dashboard',
};

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);
const singularize = string => (string.endsWith('s') ? string.substring(0, string.length - 1) : string);

/**
 * Construct title prefix for table type
 *
 * @param {string} table
 * @param {string} pathname
 * @returns {string}
 */
function titleForTable(table, pathname) {
	const pathnameEnd = urlLib.pathnameEnd(pathname) || '';
	const tableNameCapitalized = capitalize(table);
	const tableNameSingular = singularize(tableNameCapitalized);

	switch (pathnameEnd) {
	case table:
		return tableNameCapitalized;
	case 'create':
		return `Create ${tableNameSingular}`;
	case 'edit':
		return `Edit ${tableNameSingular}`;
	default:
		return `View ${tableNameSingular}`;
	}
}

/**
 * Construct the titles dynamic prefix from pathname
 *
 * @param {string} pathname
 * @returns {string}
 */
function titlePrefix(pathname) {
	const pathnameSections = urlLib.listPathnameSections(pathname);
	const [section, subSection] = pathnameSections;

	if (section === 'admin' && subSection !== undefined) {
		// Is non dashboard admin page
		return titleForTable(subSection, pathname);
	}

	return sectionTitleMap[section || ''] || '';
}

const titleForPage = pathname => `${titlePrefix(pathname)} - ${titleSuffix}`;

export default {
	titleForPage,
};
