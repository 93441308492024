/**
 * Helper functions for extracting data from pathnames
 * @module urlHelpers
 */

/**
 * List slash seperated sub routes
 *
 * @param {string} pathname
 * @returns {array}
 */
const listPathnameSections = pathname =>
	pathname
		.split("/")
		.filter(section => section)
		.map(section => section.toLowerCase());

/**
 * Remove possible trailing slash from path
 *
 * @param {string} path
 * @returns {string}
 */
function removeTrailingSlash(path) {
	if (!path.endsWith("/")) {
		return path;
	}

	return path.substring(0, path.length - 1);
}

/**
 * Remove possible leading slash from path
 *
 * @param {string} path
 * @returns {string}
 */
function removeLeadingSlash(path) {
	if (!path.startsWith("/")) {
		return path;
	}

	return path.substring(1);
}

/**
 * Join paths with a single slash between each
 *
 * @param {...string} paths
 * @returns {string}
 */
function joinPaths(...paths) {
	return paths.reduce((fullPath, path) => {
		if (!path.length) {
			return fullPath;
		}

		const pathWithoutSlashes = removeLeadingSlash(removeTrailingSlash(path));
		return `${fullPath}/${pathWithoutSlashes}`;
	}, "");
}

/**
 * Extract the base directory
 * For program urls this will be the shorthand of a programs name
 * @param {string} pathname
 * @returns {string}
 */
function pathnameStart(pathname) {
	const withoutLeadingSlash = removeLeadingSlash(pathname);
	const firstSlashPosition = withoutLeadingSlash.indexOf("/");
	const endPosition =
		firstSlashPosition !== -1 ? firstSlashPosition : withoutLeadingSlash.length;
	return withoutLeadingSlash.substring(0, endPosition);
}

/**
 * Extract the basename
 * @param {string} pathname
 * @returns {string}
 */
function pathnameEnd(pathname) {
	const withoutTrailingSlash = removeTrailingSlash(pathname);
	return withoutTrailingSlash.substring(
		withoutTrailingSlash.lastIndexOf("/") + 1
	);
}

/**
 * Extract the pathname but up one level
 * @param {string} pathname
 * @returns {string}
 */
function pathnameUp(pathname) {
	const withoutTrailingSlash = removeTrailingSlash(pathname);
	return withoutTrailingSlash.substring(
		0,
		withoutTrailingSlash.lastIndexOf("/")
	);
}

export default {
	listPathnameSections,
	removeLeadingSlash,
	removeTrailingSlash,
	pathnameStart,
	pathnameEnd,
	joinPaths,
	pathnameUp
};
