import React from "react";
import PanelLabeled from "../../../components/PanelLabeled";
import tzlookup from "tz-lookup";

import { Form, Input } from "../../../components/Form";
import { ItemCreateLayout } from "../../../components/ItemLayouts";
import { createPartner } from "../../../lib/api";

class PartnerCreatePage extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
	}

	async onSubmit({ values }) {
		const timeZone = tzlookup(
			values.geoPoint.latitude,
			values.geoPoint.longitude
		);

		return createPartner({ ...values, timeZone });
	}

	render() {
		return (
			<ItemCreateLayout itemType="Partner">
				<Form
					onSubmit={this.onSubmit}
					buttonText="Create"
					buttonLoadingText="Creating..."
					successMessage="Partner successfully created"
				>
					<div className="split">
						<div className="split__item">
							<PanelLabeled label="Information">
								<Input
									label="Name"
									placeholder="Name"
									name="name"
									type="text"
									required={true}
								/>
								<Input
									label="Latitude"
									placeholder="ex. 34.05"
									name="geoPoint.latitude"
									type="text"
									required={true}
								/>
								<Input
									label="Longitude"
									placeholder="ex. -118.24"
									name="geoPoint.longitude"
									type="text"
									required={true}
								/>
							</PanelLabeled>
						</div>
					</div>
				</Form>
			</ItemCreateLayout>
		);
	}
}

export default PartnerCreatePage;
