import React from "react";
import qs from "query-string";

import ItemList, {
	ORDER_ASCENDING,
	ORDER_DESCENDING
} from "../../../components/ItemList";
import Filter from "../../../components/Filter";
import Pagination from "../../../components/Pagination";
import { createCSV } from "../../../utils/csv";
import { ExportButton } from "../../../components/ExportButton";
import { ItemListLayout } from "../../../components/ItemLayouts";
import { getDevices } from "../../../lib/api";
import Loader from "../../../components/Loader";

const DEBOUNCE_WAIT_TIME_MS = 300;
const START_PAGE = 0;
const EXPORT_PAGE_SIZE = 18000;

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @param {function} func
 * @param {number} wait
 * @param {boolean} immediate
 */
function debounce(func, wait, immediate) {
	let timeout;

	return function construct(...args) {
		const context = this;

		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

class DevicesPage extends React.Component {
	constructor(props) {
		super(props);

		this.fetchDevices = this.fetchDevices.bind(this);
		this.updateUrlQueryString = this.updateUrlQueryString.bind(this);
		this.updateQuery = this.updateQuery.bind(this);
		this.handleColumnSort = this.handleColumnSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.handleExport = this.handleExport.bind(this);

		const updateFilterQueryDebounce = debounce(
			this.updateQuery,
			DEBOUNCE_WAIT_TIME_MS
		);
		const queryObject = qs.parse(window.location.search);

		this.state = {
			isLoading: false,
			page: parseInt(queryObject.page, 10) || START_PAGE,
			updateFilterQueryDebounce,
			devices: null,
			totalDevicesCount: 0,
			defaultSort: {
				order:
					queryObject.ascending === "true" ? ORDER_ASCENDING : ORDER_DESCENDING,

				column: queryObject.orderBy || "Created"
			}
		};
	}

	async fetchDevices() {
		this.setState({
			devices: null
		});

		const queryObject = qs.parse(window.location.search);

		const { devices, deviceCount } = await getDevices({
			...queryObject,
			orderBy: queryObject.orderBy.toUpperCase()
		});

		const devicesWithActivatedStatusCorrected = devices.map(device => ({
			...device, activated: !!device.deviceId
		}))

		this.setState({
			devices: devicesWithActivatedStatusCorrected,
			totalDevicesCount: deviceCount
		});
	}

	updateUrlQueryString(update) {
		const currentQuery = qs.parse(window.location.search);

		this.props.history.push({
			search: qs.stringify({ ...currentQuery, ...update })
		});
	}

	updateQuery(update) {
		this.updateUrlQueryString({ ...update, page: START_PAGE });
		this.setState({
			page: START_PAGE
		});
		this.fetchDevices();
	}

	handleColumnSort(options) {
		this.updateQuery({
			ascending: options.order === ORDER_ASCENDING,
			orderBy: options.column
		});
	}

	changePage(page) {
		this.setState({ page });
		this.fetchDevices();
	}

	exportButtonText = records =>
		`Export ${records} Record${records === "1" ? "" : "s"}`;

	async handleExport() {
		// could take a minute to complete so we use a loader
		this.setState({ isLoading: true });

		const queryObject = qs.parse(window.location.search);

		let page = 0;
		let results = [];
		// We make a call to getDevices for every page and add the results to the results array
		while (
			page <= Math.floor(this.state.totalDevicesCount / EXPORT_PAGE_SIZE)
		) {
			const response = await getDevices({
				...queryObject,
				orderBy: queryObject.orderBy.toUpperCase(),
				pageSize: EXPORT_PAGE_SIZE,
				page
			});

			page++;
			results = [...results, ...response.devices];
		}

		createCSV(results, "devices.csv");

		this.setState({ isLoading: false });
	}

	componentDidMount() {
		this.updateQuery({
			ascending: this.state.defaultSort.order === ORDER_ASCENDING,
			orderBy: this.state.defaultSort.column
		});
	}

	render() {
		return (
			<ItemListLayout
				itemType="Device"
				itemCount={+this.state.totalDevicesCount}
				showCreateButton={false}
			>
				<Filter onChange={this.state.updateFilterQueryDebounce} />
				{this.state.isLoading ? (
					<Loader />
				) : (
						<div className="block--sm">
							<ItemList
								itemType="Device"
								items={this.state.devices}
								loading={!this.state.devices}
								table={{
									Serial_Number: "serialNumber",
									"Program ID-noSort": "programId", // not sortable
									Resident: "residentFirstName residentLastName",
									"Program-noSort": "programName", // not sortable
									"Model-noSort": "model", // not sortable
									"Activated-noSort": "activated", //not sortable
									Created: "created",
									"Updated-noSort": "updated", // not-sortable
									"MAC-noSort": "mac", // not sortable
									"Device ID-noSort": "deviceId" // not sortable
								}}
								defaultSortColumn={this.state.defaultSort.column}
								defaultSortOrder={this.state.defaultSort.order}
								onColumnSort={this.handleColumnSort}
								compact
								simple
								noLink
							/>
						</div>
					)}

				{this.state.totalDevicesCount && (
					<div style={{ float: "right" }}>
						<ExportButton
							buttonText={this.exportButtonText(this.state.totalDevicesCount)}
							handleClick={this.handleExport}
						/>
					</div>
				)}

				<Pagination
					page={this.state.page}
					itemCount={+this.state.totalDevicesCount}
					onPageChange={this.changePage}
				/>
			</ItemListLayout>
		);
	}
}

export default DevicesPage;
