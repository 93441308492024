import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button'
import { Form, Input } from "../../components/Form";

import { loginUser } from "../../store/actions/authentication";
import { getPartners } from "../../lib/api";

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
	}

	async onSubmit(formContext) {
		const response = await this.props.dispatch(
			loginUser({
				username: formContext.values.email,
				password: formContext.values.password
			})
		);

		const partners = await getPartners()
		const noPartners = !partners || partners.length === 0
		if (noPartners) localStorage.removeItem('auth')
		
		if (response.error || noPartners) {
			return {
				error: "No user found with that username/password combination. Please contact your admin."
			};
		}

		if(JSON.parse(localStorage.getItem('auth')).role === 'USER' && partners.length > 0){
			const allowedPartners = [
				'Citrus County', // Citrus County
				'Irvine Ranch Water District', // 	Irvine Ranch Water District
				'Las Virgenes Municipal Water District', // Las Virgenes Municipal Water District
				'Moulton Niguel Water District', // Moulton Niguel Water District
				'San Antonio Water System', // San Antonio Water System
			]

			partners.map(partner => {
				allowedPartners.map(allowed => {
					if(partner.name === allowed){
						localStorage.setItem('auth', JSON.stringify({...JSON.parse(localStorage.getItem('auth')), allowRedeem: true}))
					}
				})
			})
		}

		this.props.history.push("/admin");

		return {
			redirecting: true
		};
	}

	render() {
		return (
			<Form
				onSubmit={this.onSubmit}
				buttonText="Sign In"
				buttonLoadingText="Signing In...">
				<Input label="Email" name="email" type="text" placeholder="Email" />
				<Input
					label="Password"
					name="password"
					type="password"
					placeholder="Password"/>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						onClick={() => this.props.history.push("/reset")}
						style={{ maxWidth: 'fit-content', margin: `24px 0 0`}}
						color="primary">
							{'Forgot Password?'}
					</Button>
				</div>
			</Form>
		);
	}
}

export default connect()(withRouter(LoginPage));
