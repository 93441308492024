import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class SwitchLabels extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false
		};
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
		this.props.toggleShowAll();
	};

	render() {
		return (
			<FormGroup row style={{ justifyContent: "flex-end" }}>
				<FormControlLabel
					labelPlacement="start"
					control={
						<Switch
							checked={this.state.checked}
							onChange={this.handleChange("checked")}
							value="checked"
							color="primary"
						/>
					}
					label="Show All"
				/>
			</FormGroup>
		);
	}
}

export default SwitchLabels;
