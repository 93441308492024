import React from "react";
import Helmet from "react-helmet";
import Header from "./Header";
import SubNav from "./SubNav";
// import { Redirect } from "react-router-dom";

class PortalLayout extends React.Component {
	constructor(props) {
		super(props);

		this.toggleSidebar = this.toggleSidebar.bind(this);

		this.state = {
			isUserAuthed: false,
			isSidebarOpen: true
		};
	}

	toggleSidebar() {
		this.setState({
			isSidebarOpen: !this.state.isSidebarOpen
		});
	}

	async componentDidMount() {
		// Authentication info is stored in localStorage
		const auth = localStorage.getItem("auth");

		if (!auth) {
			this.props.onUnauthorized();
			return;
		}

		this.props.onAuthorized(auth);

		this.setState({
			isUserAuthed: true,
			isSidebarOpen: window.innerWidth > 600
		});
	}

	render() {
		// Stop flashing of screen before redirecting onUnauthorized call
		if (!this.state.isUserAuthed) return null;

		const { component: Component, isAdmin } = this.props;

		return (
			<div className="site-root">
				<Helmet>
					<title>Portal | Rachio Pro</title>
				</Helmet>

				<main>
					<Header logo={this.props.logo} onLogout={this.props.onLogout} />
					<SubNav links={this.props.subnavLinks} />
					<Component isAdmin={isAdmin} {...this.props.matchProps}/>
				</main>
			</div>
		);
	}
}

export default PortalLayout;
