import React from 'react'
import PanelLabeled from '../PanelLabeled'
import AddUser from './AddUser'
import UserList from '../UserList'
import { listUsers, removeUser } from '../../lib/api'

/**
 * Simple function to sort by first name then last name
 * @param {object} a - user object
 * @param {object} b - user object
 */
const sortByName = (a, b) => {
    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1
    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1
    return 0
}

/**
 * Shared Access Component / Panel in Parner > View
 */
class SharedAccess extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
            users: []
        }
        
        this.getUsers = this.getUsers.bind(this)
        this.removeUser = this.removeUser.bind(this)
    }
    
    /**
     * Gets a list of users of the current partner
     */
    async getUsers() {
        const results = await listUsers(this.props.partnerId)
        if (results.users.length >= 0) {
            const users = results.users.sort(sortByName)
            
            this.setState({
                users
            })

        }
    }
    
    /**
     * Handles removing shared user access from partner
     * @param {string} userId 
     */
    async removeUser(userId) {
        await removeUser(this.props.partnerId, userId)
        this.getUsers()
	}

	async componentDidMount() {
        this.getUsers()
	}

	render() {
        return (
            <div>
                <PanelLabeled label='Shared Access'>
                    <AddUser 
                        listUsers={this.getUsers}
                        partnerId={this.props.partnerId}/>
                </PanelLabeled>
                {this.state.users.length > 0 && 
                <PanelLabeled panelStyle={{ padding: 0 }} label='Users'>
                    <UserList
                        users={this.state.users}
                        removeUser={this.removeUser}
                        partnerId={this.props.partnerId}
                        partnerName={this.props.partnerName}/>
                </PanelLabeled>}
            </div>
        )
    }
}

export default SharedAccess