import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './style.css';

const SubNav = props => (
	<div className='sub-nav__container'>
		<nav className='sub-nav passive container--full'>
			{Object.keys(props.links).map(link => (
				<NavLink exact={props.links[link] === '/admin'} key={link} to={props.links[link]}>{link}</NavLink>
			))}
		</nav>
	</div>
);

SubNav.propTypes = {
	links: PropTypes.object.isRequired,
};

export default SubNav;
