/**
 * Batch creates serial number records from a CSV
 *
 * CSV files are expected to list serial numbers in the first column
 * The first row will be ignored as it's expected to contain the columns header (typically 'serial_number')
 */

import React from "react";
import PropTypes from "prop-types";
import papaparse from "papaparse";

import csvExampleImg from "./csvExampleImg.png";
import Dialog from "../../components/Dialog";
import Loader from "../../components/Loader";
import { uploadSerials } from "../../lib/api";
import "./style.css";

// Length of serial numbers to be previewed after file added
const PREVIEW_LENGTH = 5;

class SerialCSVUpload extends React.Component {
	constructor(props) {
		super(props);

		this.handleFileChange = this.handleFileChange.bind(this);
		this.upload = this.upload.bind(this);

		this.state = {
			previewSerialNumbers: [],
			parsing: false,
			failed: false,
			uploadObj: {}
		};
	}

	/**
	 * Add activated value from checkbox and add to body of request
	 *
	 * @returns {object} response object
	 */
	async upload() {
		// Check to see if there is an upload object, if not return {}
		if (
			Object.keys(this.state.uploadObj).length === 0 &&
			this.state.uploadObj.constructor === Object
		) {
			return {};
		}
		const response = await uploadSerials({
			...this.state.uploadObj
		});

		if (response instanceof Error) {
			// eslint-disable-next-line no-console
			console.error("Request to upload serials failed:", response);

			this.setState({
				failed: true
			});
		}
		return response;
	}

	/**
	 * When user adds a file we parse the data
	 *
	 * @param {object} event // serials activated value
	 */
	handleFileChange(event) {
		const input = event.target;
		const reader = new FileReader();

		reader.onload = () => {
			const csvData = papaparse.parse(reader.result).data;

			// Remove first row of headers
			csvData.shift();

			// Filter for undefined values
			const SerialNumbers = csvData.filter(item => !!item[0]);

			// Create a unique set to account for duplicates
			const uniqueSerialNumbers = Array.from(new Set(SerialNumbers));

			// Create a preview from the first five values
			const previewSerialNumbers = Array.from(uniqueSerialNumbers).slice(
				0,
				PREVIEW_LENGTH
			);

			// Create an array of serial numbers
			const serial_number = uniqueSerialNumbers.map(item => item[0]);

			const uploadObj = {
				programId: this.props.programId,
				serial_number
			};

			this.setState({
				uploadObj,
				serialNumberCount: uniqueSerialNumbers.length,
				previewSerialNumbers,
				parsing: false
			});
		};

		this.setState({
			parsing: true
		});

		reader.readAsBinaryString(input.files[0]);
	}

	render() {
		return (
			<label htmlFor="file">
				<div style={{ display: "flex", alignItems: "flex-start" }}>
					<span style={{ paddingRight: "10px" }}>Upload CSV</span>
					<Dialog
						buttonLabel={"info"}
						textContent={
							"CSV files should consist of serial numbers in a single column with a header row. For example:"
						}
						titleText={"CSV Upload Instructions"}
						content={<img src={csvExampleImg} alt="Upload instructions" />}
					/>
				</div>

				<input
					name="file"
					type="file"
					label="Upload CSV"
					onChange={this.handleFileChange}
				/>
				{this.state.parsing && <Loader />}
				{!!this.state.previewSerialNumbers.length && (
					<React.Fragment>
						<div>{this.state.serialNumberCount} Serial Numbers</div>
						<div className="csv--items-preview">
							{this.state.previewSerialNumbers.map(serialNumber => (
								<div key={serialNumber}>{serialNumber}</div>
							))}
							{this.state.serialNumberCount > PREVIEW_LENGTH && <div>...</div>}
						</div>
					</React.Fragment>
				)}
			</label>
		);
	}
}

SerialCSVUpload.propTypes = {
	programId: PropTypes.string.isRequired
};

export default SerialCSVUpload;
