import { LOGIN_SUCCESS } from '../actions/authentication';

function authentication(state = {}, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				auth: action.auth,
			};
		default:
			return state;
	}
}

export default authentication;
