/**
 * Base functions for interacting with other apis
 * @module api
 */

/**
 * Construct an error object for an error returned by the server
 *
 * @private
 *
 * @param {string} [message] - Error message
 * @returns {Error}
 */
function createServerError(
	message = "An unknown server side error has occured"
) {
	const error = new Error(message);
	error.name = "ServerError";
	return error;
}

/**
 * API
 *
 * @param {string} url
 * @param {string} authUrl
 * @param {string} clientId
 * @param {string} clientSecret
 * @param {function} getBearerToken - returns bearer token for request authentication header
 */
export default function API({
	url: basicUrl,
	authUrl,
	clientId,
	clientSecret,
	getBearerToken
}) {
	/**
	 * Parse api response
	 *
	 * @private
	 * @async
	 *
	 * @param {object} response
	 * @returns {object|null|Error} - JSON data or null
	 */
	async function parseAPIResponse(response) {
		if (response.status === 500) {
			throw createServerError();
		}

		const contentType = response.headers.get("content-type");
		const isJson = contentType && contentType.includes("application/json");

		if (isJson) {
			return response.json();
		}

		return null;
	}

	/**
	 * Generic request template
	 *
	 * @private
	 * @async
	 *
	 * @param {string} url
	 * @param {object} data
	 * @param {object} options
	 * @param {string} options.method
	 * @param {string} options.bearerToken
	 * @returns {object}
	 */
	async function basicRequest(
		url,
		data,
		{ method = "POST", bearerToken = null } = {}
	) {
		const headers = {
			"Content-Type": "application/json"
		};

		if (bearerToken) {
			headers.Authorization = `Bearer ${bearerToken}`;
		}

		const requestOptions = {
			method,
			headers: new Headers(headers)
		};

		if (method !== "GET") {
			requestOptions.body = JSON.stringify(data);
		}

		const rawResponse = await fetch(`${basicUrl}${url}`, requestOptions);

		return parseAPIResponse(rawResponse);
	}

	/**
	 * Send an authenticated request
	 * Wraps a basic request to include the authorization header fetched
	 * from the store
	 *
	 * Returns an error if no access_token is in the store
	 *
	 * @private
	 * @async
	 *
	 * @param {string} url
	 * @param {object} data
	 * @param {object} options
	 * @param {string} options.method
	 * @returns {object|Error}
	 */
	async function authedRequest(url, data, { method = "POST" } = {}) {
		const bearerToken = getBearerToken();

		if (!bearerToken) {
			return new Error("No access token to make authenticated request");
		}

		return basicRequest(url, data, {
			method,
			bearerToken
		});
	}

	/**
	 * Request template for Rachio authentication/oAuth service
	 *
	 * @private
	 * @param {string} url
	 * @param {object} data
	 * @param {object} options
	 * @param {string} options.method
	 */
	async function oAuthRequest(url, data, { method = "POST" } = {}) {
		const params = new URLSearchParams();
		const formData = {
			...data,
			grant_type: "password",
			client_id: clientId,
			client_secret: clientSecret
		};

		Object.keys(formData).map(name => params.append(name, formData[name]));

		const requestOptions = {
			method,
			body: params.toString(),
			headers: new Headers({
				"Content-Type": "application/x-www-form-urlencoded"
			})
		};

		const rawResponse = await fetch(`${authUrl}${url}`, requestOptions);

		return parseAPIResponse(rawResponse);
	}

	return Object.freeze({
		basicGet: (url, options, data = {}) =>
			basicRequest(url, data, { ...options, method: "GET" }),
		basicPost: (url, data, options) =>
			basicRequest(url, data, { ...options, method: "POST" }),
		basicPut: (url, data, options) =>
			basicRequest(url, data, { ...options, method: "PUT" }),
		basicDelete: (url, data, options) =>
			basicRequest(url, data, { ...options, method: "DELETE" }),

		authedGet: (url, data, options) =>
			authedRequest(url, data, { ...options, method: "GET" }),
		authedPost: (url, data, options) =>
			authedRequest(url, data, { ...options, method: "POST" }),
		authedPut: (url, data, options) =>
			authedRequest(url, data, { ...options, method: "PUT" }),
		authedDelete: (url, data, options) =>
			authedRequest(url, data, { ...options, method: "DELETE" }),

		oAuthPost: (url, data, options) =>
			oAuthRequest(url, data, { ...options, method: "POST" })
	});
}
