/**
 * Collection of common form elements
 *
 * @module form/elements
 */

import React from "react";
import PropTypes from "prop-types";
import { Warning, Done } from "@material-ui/icons";

import { FormContext } from "../index";

/**
 * Checks and sets an elmenets default value in the parent context
 * Value is set after mounting to avoid calling setValue and therefore setState
 * in the parent component during the initial render
 */
class WithDefautlValue extends React.Component {
	constructor(props) {
		super(props);

		// May be undefined
		let { defaultValue } = props;

		if (props.defaultChecked) {
			// Radio inputs will have a value but checkbox inputs won't and should be set as boolean
			defaultValue = props.value || !!props.defaultChecked;
		}

		this.state = {
			defaultValue
		};
	}

	componentDidMount() {
		if (this.state.defaultValue) {
			this.props.formContext.setValue(this.props.name, this.state.defaultValue);
		}
	}

	render() {
		const { Component, ...rest } = this.props;
		return <Component {...rest} />;
	}
}

/**
 * HOC - Passes form context as a prop
 *
 * @param {function} Component - react component
 * @returns {function} react component with formContent prop
 */
function withFormContext(Component) {
	return props => (
		<FormContext.Consumer>
			{form => (
				<WithDefautlValue {...props} Component={Component} formContext={form} />
			)}
		</FormContext.Consumer>
	);
}

/**
 * Store value of form element in parent formcontext
 *
 * @param {object} formContext
 * @param {function} [onChange] - components original onChange prop
 */
function handleChange(formContext, onChange = null) {
	return event => {
		const { name } = event.target;
		const value =
			event.target.getAttribute("type") === "checkbox"
				? event.target.checked
				: event.target.value;

		formContext.setValue(name, value);

		if (onChange) {
			onChange(event);
		}
	};
}

/**
 * Strips certain properties from props
 *
 * @param {object} props
 * @param {array} [excluding] - properties to strip
 * @returns {object}
 */
function propsExcluding(
	props,
	excluding = ["formContext", "boxLabel", "errorMessage", "helpText"]
) {
	const object = { ...props };
	excluding.forEach(field => delete object[field]);
	return object;
}

/*
 * ------------------------------
 * Basic Elements
 * ------------------------------
 */

export const ErrorMessage = props => (
	<div className="message--error">
		<Warning className="icon vertical-center" />
		<span className="vertical-center">{props.children}</span>
	</div>
);

export const SuccessMessage = props => (
	<div className="message--success">
		<Done className="icon vertical-center" />
		<span className="vertical-center">{props.children}</span>
	</div>
);

const FieldContents = props => {
	if (["checkbox", "radio"].includes(props.type)) {
		return (
			<React.Fragment>
				{props.children}
				<span
					className="vertical-center"
					dangerouslySetInnerHTML={{ __html: props.label }}
				/>
				{(props.required || props.warningtext) && (
					<span className="required">
						{props.warningtext && (
							<p className="text--help warning--text">{props.warningtext}</p>
						)}
					</span>
				)}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<div>
				{props.label}
				{(props.required || props.warningtext) && (
					<span className="required">
						{props.warningtext && (
							<p className="text--help warning--text">{props.warningtext}</p>
						)}
					</span>
				)}
			</div>
			{props.children}
		</React.Fragment>
	);
};

export const FieldContainer = props => (
	<label
		className={props.boxLabel ? "box-label" : ""}
		htmlFor={props.type === "radio" ? props.value : props.name}
	>
		{props.errorMessage && <div>{props.errorMessage}</div>}
		<FieldContents {...props} />
		{props.helpText && <p className="text--help">{props.helpText}</p>}
	</label>
);

export const FieldSet = props => (
	<fieldset>
		<legend>
			{props.legend}
			{(props.required || props.warningtext) && (
				<span className="required">
					{props.warningtext && (
						<p className="text--help warning--text">{props.warningtext}</p>
					)}
				</span>
			)}
		</legend>
		{props.errorMessage && <div>{props.errorMessage}</div>}
		{props.helpText && <p className={"text--help"}>{props.helpText}</p>}
		{props.children}
	</fieldset>
);

FieldSet.propTypes = {
	legend: PropTypes.string.isRequired,
	helpText: PropTypes.string,
	required: PropTypes.bool
};

FieldSet.defaultProps = {
	required: false,
	helpText: undefined
};

/*
 * ------------------------------
 * Form Fields
 *
 * Form fields are wrapped in a HOC which passes the parent form context
 * as the prop `formContext`.
 * The form context comes from the parent Form component.
 *
 * Example Input
 * <Input label='First Name' name='name.first' type='text' />
 * ------------------------------
 */

export const Input = withFormContext(props => (
	<FieldContainer {...props}>
		<input
			id={props.name}
			type={props.type || "text"}
			onChange={handleChange(props.formContext, props.onChange)}
			{...propsExcluding(props)}
		/>
	</FieldContainer>
));

export const Select = withFormContext(props => (
	<FieldContainer {...props}>
		<select
			id={props.name}
			onChange={handleChange(props.formContext, props.onChange)}
			{...propsExcluding(props)}
		>
			{props.children}
		</select>
	</FieldContainer>
));

export const Checkbox = withFormContext(props => (
	<FieldContainer {...props} type="checkbox">
		<input
			id={props.name}
			type="checkbox"
			onChange={handleChange(props.formContext, props.onChange)}
			{...propsExcluding(props)}
		/>
	</FieldContainer>
));

export const Radio = withFormContext(props => (
	<FieldContainer {...props} type="radio">
		<input
			id={props.value}
			type="radio"
			onChange={handleChange(props.formContext, props.onChange)}
			{...propsExcluding(props)}
		/>
	</FieldContainer>
));

export const TextArea = withFormContext(props => (
	<FieldContainer {...props}>
		<textarea
			id={props.name}
			onChange={handleChange(props.formContext, props.onChange)}
			{...propsExcluding(props)}
		/>
	</FieldContainer>
));
