import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";

import "./style.css";
import itemLib from "../../utils/item";
import urlLib from "../../utils/url";
import Loader from "../Loader";

export const ORDER_ASCENDING = "ASCENDING";
export const ORDER_DESCENDING = "DESCENDING";
const orderIcons = {
	[ORDER_DESCENDING]: ArrowDropDown,
	[ORDER_ASCENDING]: ArrowDropUp
};
// Used as the default sorting order when a new column is clicked
const defaultSortOrder = ORDER_ASCENDING;

const oppositeOrder = order =>
	order === ORDER_ASCENDING ? ORDER_DESCENDING : ORDER_ASCENDING;

/**
 * Compare stings case insensitively (Compare function for array sort)
 *
 * Blank values will always be greater so they appear at the bottom of the list
 * when sorting in ascending order
 *
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
function compareStringCaseInsensitively(a, b) {
	if (
		(typeof a === "number" && typeof b === "number") ||
		(typeof a === "boolean" && typeof b === "boolean")
	) {
		return a - b;
	}

	if (!b) return -1;
	if (!a) return 1;

	return a.toLowerCase().localeCompare(b.toLowerCase());
}

/**
 * Sort items by key
 *
 * @param {array.<object>} items
 * @param {string} key
 * @param {string} order
 * @returns {array.<object>}
 */
function sortItems(items, key, order) {
	return items.sort((a, b) => {
		let valueA = itemLib.getObjectProperties(a, key);
		let valueB = itemLib.getObjectProperties(b, key);

		if (order === ORDER_DESCENDING) {
			[valueA, valueB] = [valueB, valueA];
		}

		return compareStringCaseInsensitively(valueA, valueB);
	});
}

class ItemList extends React.Component {
	constructor(props) {
		super(props);

		this.handleColumnClick = this.handleColumnClick.bind(this);
		this.createHeaderColumns = this.createHeaderColumns.bind(this);
		this.createColumns = this.createColumns.bind(this);
		this.sortItems = this.sortItems.bind(this);

		let sortColumn = Object.keys(this.props.table)[0];

		if (this.props.defaultSortColumn) {
			if (!this.props.table[this.props.defaultSortColumn]) {
				throw new Error("Column selected for default sorting does not exist");
			}

			sortColumn = this.props.defaultSortColumn;
		}

		const order = this.props.defaultSortOrder;
		const orderBy = this.props.table[sortColumn];
		const items = this.props.items
			? this.sortItems(this.props.items, orderBy, order)
			: null;

		this.state = {
			order,
			orderBy,
			column: sortColumn,
			items
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.items !== prevProps.items) {
			this.setState({
				items: this.sortItems(
					this.props.items,
					this.state.orderBy,
					this.state.order
				)
			});
		}
	}

	sortItems(items, orderBy, order) {
		if (this.props.onColumnSort) {
			// Items are sorted outside of component
			return items;
		}

		return sortItems(items, orderBy, order);
	}

	handleColumnClick(column) {
		const key = this.props.table[column];
		const orderBy = key instanceof Object ? key.field : key;
		const order =
			this.state.orderBy === orderBy
				? oppositeOrder(this.state.order)
				: defaultSortOrder;

		this.setState({
			order,
			orderBy,
			column
		});

		if (this.props.onColumnSort) {
			this.props.onColumnSort({ order, orderBy, column });
		} else {
			this.setState({
				items: this.sortItems(this.props.items, orderBy, order)
			});
		}
	}

	/**
	 * @param {Array} ColumnNames
	 */
	createHeaderColumns(columnNames) {
		const tds = columnNames.map(column => {
			const isActiveSortColumn = column === this.state.column;
			const orderOfColumn =
				isActiveSortColumn && this.state.order !== defaultSortOrder
					? oppositeOrder(defaultSortOrder)
					: defaultSortOrder;
			const OrderIcon = orderIcons[orderOfColumn];

			return column.includes("-noSort") ? (
				<td key={column} className={"noSort"}>
					{column.replace("-noSort", "")}
				</td>
			) : (
				<td
					key={column}
					className={isActiveSortColumn ? "active" : ""}
					onClick={this.handleColumnClick.bind(this, column)}
				>
					{column.replace("_", " ")} {<OrderIcon />}
				</td>
			);
		});

		// Display edit button as last column
		if (this.props.compact && !this.props.simple) {
			tds.push(
				<td key={"last-column-edit-button"} className="hidden">
					Edit
				</td>
			);
		}

		return tds;
	}

	/**
	 * @typedef {Object} ColumnCustomType
	 * @property {string} field - property string to pass to component function
	 * @property {function} type - type (exported from rachio-portal-shared item util) defines how to convert the value
	 *
	 * @example
	 * {
	 *   field: 'rating',
	 *   type: TYPE_DATE_RELATIVE
	 * }
	 */

	/**
	 * @typedef {Object} ColumnReact
	 * @property {string} field - property string to pass to component function
	 * @property {function} component - function which returns react component
	 *
	 * @example
	 * {
	 *   field: 'rating',
	 *   component: rating => <Stars rating={rating} />,
	 * }
	 */

	/**
	 * @param {object} item
	 * @param {string|ColumnReact|ColumnCustomType} value - either property string of item or ColumnReact
	 */
	getColumnValue(item, property) {
		if (property instanceof Object) {
			// Uses custom options
			const options = property;

			if (options.component) {
				// Value is of type ColumnReact
				const value = itemLib.getObjectProperties(item, options.field, {
					userFriendly: true
				});

				return options.component(value);
			}

			if (options.type) {
				return itemLib.getObjectProperties(item, options.field, {
					type: options.type,
					userFriendly: true
				});
			}
		}

		return itemLib.getObjectProperties(item, property, {
			userFriendly: true
		});
	}

	/**
	 * @param {Array} columnNames
	 * @param {Object} item
	 */
	createColumns(columnNames, item) {
		const linkBase = this.props.linkPrefix || window.location.pathname;
		const linkLocation = urlLib.joinPaths(
			linkBase,
			item.id ? item.id : item.programId ? item.programId : ''
		)

		const tds = columnNames.map((column, index) => {
			const value = this.props.table[column];
			const columnValue = this.getColumnValue(item, value);

			// small hack to round dollar amount to nearest hundreth and not show decimals if even dollar amount
			// if ( typeof columnValue === 'string' && columnValue.startsWith('$')) {
			// 	let v = columnValue.replace('$', '')
			// 	v = Number(v).toFixed(2)
			// 	if (v % 1 !== 0) {
			// 		columnValue = `$${v}`
			// 	}
			// }

			if (column === "Resident" && this.props.itemType === "Device") {
				return (
					<td key={`${index}-${column}`}>
						<NavLink
							className="link"
							to={`/admin/residents/${item.residentId}`}
						>
							{columnValue}
						</NavLink>
					</td>
				);
			}
			if (column === 'Email') {
				if (this.props.compact) {
					return (
						<td key={`${index}-${column}`}>
							{this.props.noLink || !this.props.isAdmin ? (
								columnValue
							) : (
								<NavLink className="link" to={linkLocation}>
									{columnValue}
								</NavLink>
							)}
						</td>
					);
				}
			}

			if ((column === 'Program' && this.props.itemType === 'Program') || (column === 'Partner' && this.props.itemType === 'Partner')) {
				if (this.props.compact) {
					return (
						<td key={`${index}-${column}`}>
							{this.props.noLink ? (
								columnValue
							) : (
								<NavLink className="link" to={linkLocation}>
									{columnValue}
								</NavLink>
							)}
						</td>
					);
				}

				return (
					<td key={`${index}-${column}`}>
						<div className="primary-column-header">
							<strong>{columnValue}</strong>
						</div>
						<ul className="list--vertical">
							<li className="list__item ">
								<NavLink className="link" to={linkLocation}>
									View
								</NavLink>
							</li>
							{this.props.isAdmin && 
								<li className="list__item">
									<NavLink className="link" to={`${linkLocation}/edit`}>
										Edit
									</NavLink>
								</li>
							}
						</ul>
					</td>
				);
			}

			return <td key={`${index}-${column}`}>{columnValue}</td>;
		});

		// Display edit button as last column
		if (this.props.compact && !this.props.simple && this.props.isAdmin) {
			tds.push(
				<td key={`${item.id}-edit`}>
					<NavLink className="link" to={`${linkLocation}/edit`}>
						Edit
					</NavLink>
				</td>
			);
		}

		return tds;
	}

	render() {
		const columnNames = Object.keys(this.props.table);
		const areItemsLoading = this.props.loading || !this.state.items;

		return (
			<table>
				<thead>
					<tr>{this.createHeaderColumns(columnNames)}</tr>
				</thead>
				<tbody>
					{areItemsLoading ? (
						<tr>
							<td colSpan="100">
								<Loader />
							</td>
						</tr>
					) : (
						this.state.items.map((item, index) => (
							<tr key={index}>{this.createColumns(columnNames, item)}</tr>
						))
					)}
				</tbody>
			</table>
		);
	}
}

ItemList.propTypes = {
	// Data
	items: PropTypes.arrayOf(PropTypes.object),
	// Description of table rows to be displayed
	// Object shape 1:
	// {
	//   'Displayed Column Name': 'propertyOnDataObjects'
	// }
	//
	// Object shape 2:
	// {
	//   'Displayed Column Name': {
	//	   field: 'propertyOnDataObjects',
	//	   type: 'date-relative',
	//	 },
	// }
	//
	// Object shape 3:
	// {
	//   'Displayed Column Name': {
	//	   field: 'propertyOnDataObjects',
	//	   component: property => <Component max={property} />,
	//	 },
	// }
	//
	// Example:
	// {
	//   'Email': 'email',
	//   'Name': 'nameDisplay',
	//   'Pro': 'pro.name',
	//   'Branch': 'branch.name',
	//   'Rating': {
	//     field: 'rating',
	//     component: rating => <Stars rating={rating} />,
	//   },
	//   'Created': 'created',
	// }
	table: PropTypes.object.isRequired,

	// Modes
	// Don't display item view and edit links in first column
	compact: PropTypes.bool,
	// Don't display item edit link as final column
	simple: PropTypes.bool,
	// Should first column be a view link in (requries compact mode)
	noLink: PropTypes.bool,

	// Defaults
	defaultSortColumn: PropTypes.string,
	// Used for the initial ordering
	// Subsequent ordering of columns will use the defaultSortOrder defined at top of file
	defaultSortOrder: PropTypes.oneOf([ORDER_ASCENDING, ORDER_DESCENDING]),
	// On sort function is passed the arguments: { items, orderBy, order
	// Expected to return the sorted array of items
	onColumnSort: PropTypes.func,

	// Set a link base other than the default window.location.pathname
	// Used on dashboard pages
	linkBase: PropTypes.string
};

ItemList.defaultProps = {
	// Items may be loading
	items: null,
	compact: false,
	simple: false,
	noLink: false,
	defaultSortColumn: null,
	defaultSortOrder,
	onColumnSort: null,
	linkBase: null
};

export default ItemList;
