import React from "react";

import authLib from "../../../lib/auth";
import ItemList from "../../../components/ItemList";
import { ItemListLayout } from "../../../components/ItemLayouts";
import { getPartners } from "../../../lib/api";

class PartnersPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			partners: null
		};
	}

	async componentDidMount() {
		const partners = await getPartners();
		const nonRachioPartners = partners.filter(
			partner => !authLib.isRachioPartner(partner)
		);

		this.setState({
			partners: nonRachioPartners
		});
	}

	render() {
		const { partners } = this.state;

		return (
			<ItemListLayout
				itemType="Partner"
				itemCount={partners && partners.length}
				isAdmin={this.props.isAdmin}
			>
				<ItemList
					isAdmin={this.props.isAdmin}
					itemType="Partner"
					items={partners}
					table={{
						Partner: "name",
						Enabled: "enabled",
						"Time Zone": "timeZone",
						Zip: "zip",
						Website: "website"
					}}
				/>
			</ItemListLayout>
		);
	}
}

export default PartnersPage;
