/**
 * Batch creates resident records from a CSV
 *
 * CSV files are expected to list account numbers in the first column
 * The first row will be ignored as it's expected to contain the columns header (typically 'account_number')
 */

import React from "react";
import PropTypes from "prop-types";
import papaparse from "papaparse";

import csvExampleImg from "./csvExampleImg.png";
import Dialog from "../../components/Dialog";
import Loader from "../../components/Loader";
import LoaderProgress from "../../components/LoaderProgress";

import "./style.css";
import { createResidents } from "../../lib/api";

// Number of residents to create per request
// Resident creation is batched as grpc requests can time out
const BATCH_SIZE = 1000;
const PREVIEW_LENGTH = 10;

class CSVUpload extends React.Component {
	constructor(props) {
		super(props);

		this.handleFileChange = this.handleFileChange.bind(this);
		this.upload = this.upload.bind(this);

		this.state = {
			items: [],
			previewAccountNumbers: [],
			uploadedCount: 0,
			parsing: false,
			uploading: true,
			uploadStarted: false,
			failed: false
		};
	}

	async upload() {
		if (!this.state.items.length) {
			return null;
		}

		this.setState({
			uploading: true,
			uploadStarted: true,
			uploadedCount: 0
		});

		console.log('uploading residents with batch size ' + BATCH_SIZE)
		for (let i = 0; i < this.state.items.length; i += BATCH_SIZE) {
			const start = i;
			const end = Math.min(i + BATCH_SIZE, this.state.items.length);
			const batch = this.state.items.slice(start, end);

			// eslint-disable-next-line no-await-in-loop
			const response = await createResidents(batch);

			if (response instanceof Error || response.message) {
				// eslint-disable-next-line no-console
				console.error("Request to create residents failed:", response);

				this.setState({
					failed: true
				});

				// Stop uploading
				return response;
			}

			this.setState({
				uploadedCount: end
			});
		}

		this.setState({
			uploading: false,
			items: []
		});

		return null;
	}

	handleFileChange(event) {
		const input = event.target;
		const reader = new FileReader();

		reader.onload = () => {
			const csvData = papaparse.parse(reader.result).data;

			// Remove first row of headers
			csvData.shift();

			const accountNumbers = csvData.filter(item => !!item[0]);
			const uniqueAccountNumbers = Array.from(new Set(accountNumbers));
			const previewAccountNumbers = Array.from(uniqueAccountNumbers).slice(
				0,
				PREVIEW_LENGTH
			);
			const items = uniqueAccountNumbers.map(item => ({
				accountNumber: item[0],
				programId: this.props.programId
			}));

			this.setState({
				items,
				accountNumberCount: uniqueAccountNumbers.length,
				previewAccountNumbers,
				parsing: false
			});
		};

		this.setState({
			parsing: true
		});

		reader.readAsBinaryString(input.files[0]);
	}

	render() {
		return (
			<label htmlFor="file">
				<div style={{ display: "flex", alignItems: "flex-start" }}>
					<span style={{ paddingRight: "10px" }}>Upload CSV</span>
					<Dialog
						buttonLabel={"info"}
						textContent={
							"CSV files should consist of account numbers in a single column with a header row. For example:"
						}
						titleText={"CSV Upload Instructions"}
						content={<img src={csvExampleImg} alt="Upload instructions" />}
					/>
				</div>

				{this.state.uploadStarted && (
					<div className="block--sm">
						<LoaderProgress
							progress={
								(this.state.uploadedCount / this.state.accountNumberCount) * 100
							}
							subtext={`${this.state.uploadedCount}/${
								this.state.accountNumberCount
							} Uploaded`}
							failed={this.state.failed}
						/>
					</div>
				)}

				<input
					name="file"
					type="file"
					label="Upload CSV"
					onChange={this.handleFileChange}
				/>

				{this.state.parsing && <Loader />}

				{!!this.state.previewAccountNumbers.length && (
					<React.Fragment>
						<div>{this.state.accountNumberCount} Account Numbers</div>

						<div className="csv--items-preview">
							{this.state.previewAccountNumbers.map(accountNumber => (
								<div key={accountNumber}>{accountNumber}</div>
							))}
							{this.state.accountNumberCount > PREVIEW_LENGTH && <div>...</div>}
						</div>
					</React.Fragment>
				)}
			</label>
		);
	}
}

CSVUpload.propTypes = {
	// Id of program account numbers belong to
	programId: PropTypes.string.isRequired
};

export default CSVUpload;
