import React from "react";
import qs from "query-string";
import PanelLabeled from "../../../components/PanelLabeled";

import ItemList, {
	ORDER_ASCENDING,
	ORDER_DESCENDING
} from "../../../components/ItemList";
import BannerHeader from "../../../components/BannerHeader";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination";
import StatsBar from "../../../components/StatsBar";
import { ExportButton } from "../../../components/ExportButton";
import { createCSV } from "../../../utils/csv";
import { getResidents, getPartners, getPrograms } from "../../../lib/api";
import { partnerUserTable, partnerAdminTable, RachioAdminTable } from "./tables"
import ExpansionPanel from './panel'
import "./style.css";

const DEBOUNCE_WAIT_TIME_MS = 300;
const START_PAGE = 0;
const EXPORT_PAGE_SIZE = 18000;

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @param {function} func
 * @param {number} wait
 * @param {boolean} immediate
 */
function debounce(func, wait, immediate) {
	let timeout;

	return function construct(...args) {
		const context = this;

		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

const getOrderByMap = (orderby) => {
	switch(orderby) {
		case "ACCOUNT NUMBER":
			return "ACCOUNT"
		case "REDEMPTION STATUS":
			return "REDEEMED"
		default:
			return orderby
	}
} 

class ResidentsPage extends React.Component {
	constructor(props) {
		super(props);

		this.fetchResidents = this.fetchResidents.bind(this);
		this.updateUrlQueryString = this.updateUrlQueryString.bind(this);
		this.updateQuery = this.updateQuery.bind(this);
		this.handleColumnSort = this.handleColumnSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.setIsPartnerAdmin = this.setIsPartnerAdmin.bind(this);

		const updateFilterQueryDebounce = debounce(
			this.updateQuery,
			DEBOUNCE_WAIT_TIME_MS
		);
		const queryObject = qs.parse(window.location.search);

		this.state = {
			page: parseInt(queryObject.page, 10) || START_PAGE,
			loading: false,
			updateFilterQueryDebounce,
			residents: null,
			totalResidentsCount: 0,
			haveEmailCount: 0,
			redeemedCount: 0,
			partners: {},
			programs: {},
			selectedPartners: [],
			selectedPrograms: [],
			isPartnerAdmin: false,
			defaultSort: {
				order:
					queryObject.ascending === "true" ? ORDER_ASCENDING : ORDER_DESCENDING,
				column: queryObject.orderBy || "Updated"
			}
		};
	}

	async setIsPartnerAdmin() {
		const partners = await getPartners()

		if (partners.length > 0) {
			const partnerPermissions = partners.reduce((acc, partner) => {
				if (!acc.includes(partner.portalRole)) acc.push(partner.portalRole)
				return acc
			}, [])
			this.setState({
				isPartnerAdmin: partnerPermissions.includes('portal_admin')
			})
			return partnerPermissions.includes('portal_admin')
		}
	}

	async fetchResidents() {
		this.setState({
			residents: null
		});

		const queryObject = qs.parse(window.location.search);
		if (typeof queryObject.programs === 'string') {
			queryObject.programs = [queryObject.programs]
		} 
		if (typeof queryObject.partners === 'string') {
			queryObject.partners = [queryObject.partners]
		} 

		const {
			haveEmailCount,
			redeemedCount,
			residentCount,
			residents
		} = await getResidents({
			...queryObject,
			orderBy: getOrderByMap(queryObject.orderBy.toUpperCase()),
			useChildren: true
		});

		const partners = await getPartners()
		const programs = await getPrograms()

		const temporalIsPartnerAdmin = await this.setIsPartnerAdmin()

		const formattedResidents = residents.map(r => {
			if(temporalIsPartnerAdmin && !JSON.parse(localStorage.getItem('auth')).allowRedeem) {
				if(r.address && r.redeemed) {
					return ({...r, address: r.address.addressLine1 + ' ' + r.address.addressLine2, city: r.address.city, postalCode: r.address.postalCode})
				} else {
					return ({...r, address: '', city: '', postalCode: '', firstName: '', lastName: '', emailPrimary: ''})
				}
			} else {
				if(r.address) {
					return ({...r, address: r.address.addressLine1 + ' ' + r.address.addressLine2, city: r.address.city, postalCode: r.address.postalCode})
				} else {
					return r
				}
			}
		})

		this.setState({
			haveEmailCount,
			partners,
			programs,
			redeemedCount,
			residents: formattedResidents,
			totalResidentsCount: residentCount
		});
	}

	updateUrlQueryString(update) {
		const currentQuery = qs.parse(window.location.search);

		this.props.history.push({
			search: qs.stringify({ ...currentQuery, ...update })
		});
	}

	updateQuery(update) {
		this.updateUrlQueryString({ ...update, page: START_PAGE });
		this.setState({
			page: START_PAGE
		});
		this.fetchResidents();
	}

	handleColumnSort(options) {
		this.updateQuery({
			ascending: options.order === ORDER_ASCENDING,
			orderBy: options.column
		});
	}

	changePage(page) {
		this.setState({ page });
		this.fetchResidents();
	}

	componentDidMount() {
		this.updateQuery({
			ascending: this.state.defaultSort.order === ORDER_ASCENDING,
			orderBy: this.state.defaultSort.column
		});
	}

	exportButtonText = (records, withEmail) =>
		`Export ${records} ${
		withEmail ? " With Email" : `${records === "1" ? "Record" : "Records"}`
		}`;

	async handleExport(withEmail = false) {
		// could take a minute to complete so we use a loader
		this.setState({ isLoading: true });

		const queryObject = qs.parse(window.location.search);

		if (typeof queryObject.programs === 'string') {
			queryObject.programs = [queryObject.programs]
		} 
		if (typeof queryObject.partners === 'string') {
			queryObject.partners = [queryObject.partners]
		} 

		let page = 0;
		let results = [];
		let exportSize = this.state.totalResidentsCount;

		// If user selects to export with email without query parameters (export all users with email)
		// its much faster to make getResidents call with query of '@' and then export results.
		if (withEmail && !queryObject.query) {
			queryObject.query = "@";
			exportSize = this.state.haveEmailCount;
		}

		// We make a call to getResidents 10,000 records at a time until we have all the results.
		while (page <= Math.floor(exportSize / EXPORT_PAGE_SIZE)) {
			const response = await getResidents({
				...queryObject,
				orderBy: getOrderByMap(queryObject.orderBy.toUpperCase()),
				pageSize: EXPORT_PAGE_SIZE,
				page
			});

			page++;
			results = [
				...results,
				...(withEmail
					? response.residents.filter(r => r.emailPrimary)
					: response.residents)
			];
		}

		// mark redeemed residents as 'Yes' or 'No' in export
		results = results.map(r => ({...r, redeemed: r.redeemed ? 'Yes' : 'No'}))

		// create the csv file from the residents and initialize a download of the csv file
		createCSV(results, "residents.csv");

		this.setState({ isLoading: false });
	}

	render() {
		return (
			<React.Fragment>
				<div className="block__container banner__container">
					<div className="block--sm container--full">
						<BannerHeader
							headerText="Residents"
							showCreateButton
							itemType={"Resident"}
							isAdmin={this.props.isAdmin}
						/>
					</div>
				</div>
				{this.state.isLoading ? (
					<Loader />
				) : (
						<div className="container--full">
							<div className="block--sm">
								<div className="panel">
									<StatsBar
										stats={[
											{
												text: "Eligible Accounts",
												stat: this.state.totalResidentsCount
											},
											{
												text: "Started Redemption Process",
												stat: this.state.haveEmailCount
											},
											{
												text: "Accounts Redeemed",
												stat: this.state.redeemedCount
											}
										]}
									/>
								</div>
							</div>

							<div className="block--md">
								<PanelLabeled label="All Residents" panelStyle={{paddingTop: 8}}>
										<ExpansionPanel 
											partners={this.state.partners}
											programs={this.state.programs}
											selectedPartners={this.state.selectedPartners}
											selectedPrograms={this.state.selectedPrograms}
											setSelectedItems={item => this.setState(item)}
											history={this.props.history}
											fetchResidents={this.fetchResidents}
											clearFilter={() => this.setState({selectedPrograms: [], selectedPartners: []})}
											updateQuery={this.updateQuery}
											onSearchChange={this.state.updateFilterQueryDebounce}
											/>
									<ItemList
										isAdmin={this.props.isAdmin}
										itemType="Resident"
										items={this.state.residents}
										loading={!this.state.residents}
										table={this.props.isAdmin 
											? RachioAdminTable 
											: this.state.isPartnerAdmin 
											? partnerAdminTable
											: partnerUserTable
										}
										defaultSortColumn={this.state.defaultSort.column}
										defaultSortOrder={this.state.defaultSort.order}
										onColumnSort={this.handleColumnSort}
										compact
									/>
								</PanelLabeled>
							</div>

							{Boolean(this.state.totalResidentsCount) && (
								<div style={{ float: "right" }}>
									<ExportButton
										buttonText={this.exportButtonText(
											this.state.totalResidentsCount
										)}
										handleClick={() => this.handleExport(false)}
									/>
									{(this.state.totalResidentsCount !== this.state.haveEmailCount) && (this.props.isAdmin || this.state.isPartnerAdmin ) && (
											<ExportButton
												buttonText={this.exportButtonText(
													this.state.haveEmailCount,
													true
												)}
												handleClick={() => this.handleExport(true)}
											/>
										)}
								</div>
							)}

							<Pagination
								page={this.state.page}
								itemCount={+this.state.totalResidentsCount}
								onPageChange={this.changePage}
							/>
						</div>
					)}
			</React.Fragment>
		);
	}
}


export default ResidentsPage;
