/**
 * Collection of helper function for augmenting string
 * @module text
 */

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

/**
 * Return the pluar or singular version of string depending on count
 *
 * @example
 * pluralize('lead', 1)
 * // returns lead
 * @example
 * pluralize('lead', 3)
 * // returns leads
 * @example
 * pluralize('branch', 3, true)
 * // returns branches
 *
 * @param {string} string
 * @param {number} count
 * @param {boolean} endsInSibilant - suffix of plural will be 'es' instead of 's'
 * @returns {string}
 */
function pluralize(string, count, endsInSibilant = false) {
	const pluralSuffix = endsInSibilant ? "es" : "s";
	return `${string}${count === 1 ? "" : pluralSuffix}`;
}

/**
 * Strip all characters which aren't numberic or alphanbetical
 *
 * @param {string} string
 * @returns {string}
 */
const stripNonAlphanumeric = string => string.replace(/[^a-zA-Z0-9]/g, "");

/**
 * Transform phone number consisting of only numbers (non spaces) to formatted
 * phone number with special characters and spaces
 *
 * @param {string} number
 * @returns {string}
 */
const formatPhoneNumber = number =>
	`(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;

/**
 * Format date object into string
 *
 * @param {string} date
 * @returns {string}
 */
function formatDate(date) {
	const d = new Date(date);
	return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

export default {
	pluralize,
	stripNonAlphanumeric,
	formatPhoneNumber,
	formatDate
};
