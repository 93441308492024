import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class DeleteButton extends React.Component {
	state = {
		open: false
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		return (
			<div>
				<IconButton
					aria-label="Delete"
					style={{ ...this.props.style }}
					onClick={this.handleClickOpen}
				>
					<DeleteIcon />
				</IconButton>
				<Dialog
					open={this.state.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">
						{`Delete ${this.props.type}?`}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							{`Are you sure you want to delete ${this.props.item}?`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							{"Cancel"}
						</Button>
						<Button onClick={this.props.handleDelete} color="primary">
							{"Delete"}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

DeleteButton.propTypes = {
	style: PropTypes.object,
	handleDelete: PropTypes.func.isRequired,
	item: PropTypes.string,
	type: PropTypes.string
};

export default DeleteButton;
