/**
 * Batch link serial numbers and account numbers records from a CSV
 *
 * CSV files are expected to list account numbers in the first column and account numbers in the second column
 * The first row will be ignored as it's expected to contain the columns header (typically 'serial_number')
 */

import React from "react";
import PropTypes from "prop-types";
import papaparse from "papaparse";

import Dialog from "../../components/Dialog";
import Loader from "../../components/Loader";
import { devicesToResident } from "../../lib/api";
import csvExampleImg from "./csvExampleImg.png";
import "./style.css";

class DevicesToResidents extends React.Component {
	constructor(props) {
		super(props);

		this.handleFileChange = this.handleFileChange.bind(this);
		this.upload = this.upload.bind(this);

		this.state = {
			parsing: false,
			failed: false,
			uploadData: {}
		};
	}

	/**
	 * If upload data exists, create a request with that data and return the response.
	 *
	 * @returns {object} response object
	 */
	async upload() {
		const { uploadData } = this.state;

		// if there is nothing to upload, return an empty object
		if (
			Object.keys(uploadData).length === 0 &&
			uploadData.constructor === Object
		) {
			return {};
		}

		const response = await devicesToResident({
			serials: uploadData
		});

		if (response instanceof Error) {
			this.setState({
				failed: true
			});
		}
		return response;
	}

	/**
	 * When user adds a file we parse the data
	 *
	 * @param {object} event
	 */
	handleFileChange(event) {
		const input = event.target;
		const reader = new FileReader();

		reader.onload = () => {
			const csvData = papaparse.parse(reader.result).data;

			// Remove first row of headers
			csvData.shift();

			// Filter for undefined values
			const accountSerialNums = csvData.filter(item => !!item[0] && !!item[1]);

			// Create an array of serial numbers
			const uploadData = accountSerialNums.map(item => ({
				account_number: item[0],
				program_id: this.props.programId,
				serial_number: item[1]
			}));

			this.setState({
				uploadData,
				parsing: false
			});
		};

		this.setState({
			parsing: true
		});

		reader.readAsBinaryString(input.files[0]);
	}

	render() {
		return (
			<label htmlFor="file">
				<div style={{ display: "flex", alignItems: "flex-start" }}>
					<span style={{ paddingRight: "10px" }}>Upload CSV</span>
					<Dialog
						buttonLabel={"info"}
						textContent={
							"CSV files should consist of two columns; account numbers with a header row and serial numbers with a header row. Follow the example below:"
						}
						titleText={"CSV Upload Instructions"}
						content={<img src={csvExampleImg} alt="Upload instructions" />}
					/>
				</div>

				<input
					name="file"
					type="file"
					label="Upload CSV"
					onChange={this.handleFileChange}
				/>
				{this.state.parsing && <Loader />}
			</label>
		);
	}
}

DevicesToResidents.propTypes = {
	programId: PropTypes.string.isRequired
};

export default DevicesToResidents;
