/**
 * @module auth
 */

// Hard coded value of name of the Rachio partner in db
// Users belonging to the Rachio partner have admin rights
// This check should really be validated by a property in the db
const rachioPartnerName = 'Rachio';

/**
 * Check if a partner is the Rachio partner
 *
 * @param {object} partner
 * @return {boolean}
 */
function isRachioPartner(partner) {
	return partner && partner.name === rachioPartnerName;
}

export default {
	isRachioPartner,
};
