import Joi from "joi-browser";

const create = Joi.object()
	.keys({
		name: Joi.string()
			.required()
			.label("Program Name")
	})
	.unknown(true);

export default {
	create
};
