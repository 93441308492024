import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import './style.css';

class AuthLayout extends React.Component {
	async componentDidMount() {
		// Check if user is already authed
		const auth = localStorage.getItem('auth');

		if (auth) {
			this.props.onAuthorized();
		}
	}

	render() {
		const { component: Component, ...rest } = this.props;

		return (
			<Route {...rest} render={matchProps => (
				<div className='site-root'>
					<main>
						<div className='auth-wrapper'>
							<div className='auth-container container--sm'>
								<div className='text-center logo-wrapper'>
									<img className='signin-logo' src={this.props.logo} alt='Rachio Logo' />
								</div>

								<div className='block--md panel panel--lg'>
									<Component {...matchProps} />
								</div>
							</div>
						</div>
					</main>
				</div>
			)} />
		);
	}
}

AuthLayout.propTypes = {
	logo: PropTypes.string.isRequired,
	// Generally redirect user to portal home page
	onAuthorized: PropTypes.func.isRequired,
	// Page react component
	component: PropTypes.func.isRequired,
};

export default AuthLayout;
