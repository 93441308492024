import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import swal from "sweetalert";

import "./style.css";
import urlLib from "../../utils/url";

class BannerHeader extends React.Component {
	constructor(props) {
		super(props);

		this.confirmDeletion = this.confirmDeletion.bind(this);
	}

	async confirmDeletion() {
		const answer = await swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover.",
			icon: "warning",
			buttons: {
				cancel: true,
				confirm: {
					text: "Delete",
					closeModal: false
				}
			},
			dangerMode: true
		});

		if (!answer) return;

		const deleteResponse = await this.props.deleteHandler();

		if (deleteResponse.error) {
			swal("Deletion failed", {
				icon: "error"
			});
			return;
		}

		swal(`${this.props.itemType} deleted`, {
			icon: "success"
		});

		this.props.history.push(urlLib.pathnameUp(window.location.pathname));
	}

	render() {
		const { props } = this;

		return (
			<div className="banner">
				<h1 className="banner-header">
					{props.showReturnButton && (
						<NavLink to={`${urlLib.pathnameUp(window.location.pathname)}`}>
							<span className="link--passive icon icon--lg">
								<ChevronLeft />
							</span>
						</NavLink>
					)}
					<span className="icon-text">{props.headerText}</span>
				</h1>

				<div className="banner-buttons">
					{props.showDeleteButton && (
						<span
							className="button button--lg button--warning"
							onClick={this.confirmDeletion}
						>
							Delete
						</span>
					)}

					{props.showEditButton && props.isAdmin && (
						<NavLink
							className="button button--lg"
							to={urlLib.joinPaths(window.location.pathname, "/edit")}
						>
							Edit {props.itemType}
						</NavLink>
					)}

					{props.showCreateButton && props.isAdmin && (
						<NavLink
							className="button button--lg"
							to={urlLib.joinPaths(window.location.pathname, "/create")}
						>
							New {props.itemType}
						</NavLink>
					)}
				</div>
			</div>
		);
	}
}

BannerHeader.propTypes = {
	// Partner, Program, etc...
	itemType: PropTypes.string.isRequired,
	showReturnButton: PropTypes.bool,
	showEditButton: PropTypes.bool,
	showCreateButton: PropTypes.bool,
	showDeleteButton: PropTypes.bool,
	deleteHandler: (props, propName) => {
		const isFunction = typeof props[propName] === "function";

		if (props.showDeleteButton && !isFunction) {
			return new Error(
				`A ${propName} function must be provided if showDeleteButton is set.`
			);
		}

		if (props[propName] !== undefined && !isFunction) {
			return new Error(`Expected ${propName} to be of type "function"`);
		}

		return undefined;
	}
};

BannerHeader.defaultValue = {
	showReturnButton: false,
	showEditButton: false,
	showCreateButton: false,
	showDeleteButton: false,
	deleteHandler: null
};

export default withRouter(BannerHeader);
