import rootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'

function configureStore(preloadedState) {
	const middlewares = [thunkMiddleware]
	const middlewareEnhancer = applyMiddleware(...middlewares)
	const enhancers = [middlewareEnhancer]
	const composedEnhancers = composeWithDevTools(...enhancers)
	const store = createStore(rootReducer, preloadedState, composedEnhancers)
	return store
}

const store = configureStore()

export default store