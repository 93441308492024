import { getUser, login } from '../../lib/api';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
const PORTAL_ROLES = ['PORTAL_USER', 'PORTAL_ADMIN']

export const setAuth = auth => async dispatch =>
	dispatch({
		type: LOGIN_SUCCESS,
		auth,
	});

/**
 * Login user using session cookies
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const loginUser = credentials => async (dispatch) => {
	const json = await login(credentials);

	if (json.error) {
		dispatch({
			type: LOGIN_FAILURE,
		});
		return json;
	}

	// Get the rachio user object from their id and token
	const { user } = await getUser(json);
	
	// Check if user object has portal roles
	const isPortalUser = PORTAL_ROLES.some(r => user.role.indexOf(r) >= 0)
	
	if (!isPortalUser) {
		dispatch({
			type: LOGIN_FAILURE,
		})
		return {
			error: true
		}
	}

	// add their role to the auth object
	const role = user.role.includes('PORTAL_ADMIN') ? 'ADMIN' : 'USER'
	const auth = { ...json, role }
	
	// For use after page reload or separate tabs
	localStorage.setItem('auth', JSON.stringify(auth, null, 2));

	dispatch({
		type: LOGIN_SUCCESS,
		auth
	});
	return json;
};


/**
 * Logout user from session
 */
export const logoutUser = () => async (dispatch) => {
	localStorage.removeItem('auth');
	return dispatch({
		type: LOGOUT,
	});
};
