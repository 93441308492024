/**
 * Central file for sharing constant data across the web app
 * @module constants
 */

// eslint-disable-next-line import/prefer-default-export
export const programStatus = {
	ACTIVE: "Active",
	INACTIVE: "Inactive",
	ARCHIVED: "Archived",
	COMPLETED: "Completed",
	UPCOMING: "Upcoming",
	DEMO: "Demo"
};
