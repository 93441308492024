import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const LoaderProgress = props => (
	<React.Fragment>
		<div className={`progress-bar ${props.failed ? 'progress-bar--failed' : ''}`}>
			<div className='progress-bar__fill' style={{ width: `${props.progress}%` }} />
		</div>

		{!!props.subtext && (
			<p className='progress-bar__subtext'>{props.subtext}</p>
		)}
	</React.Fragment>
);

LoaderProgress.propTypes = {
	progress: PropTypes.number.isRequired,
	subtext: PropTypes.string,
	failed: PropTypes.bool,
};

LoaderProgress.defaultProps = {
	subtext: null,
	failed: false,
};

export default LoaderProgress;
