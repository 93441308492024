import { TYPE_DATE_RELATIVE, TYPE_NORMAL, TYPE_REDEEMED } from "../../../utils/item";


export const partnerUserTable = {
	"Muni-noSort": "muniName",
	Program: "programName",
	'Account number': {
		field: "accountNumber",
		type: TYPE_NORMAL
	},
	'Redemption Status': {
		field: "redeemed",
		type: TYPE_REDEEMED
	},
	Updated: {
		field: "updated",
		type: TYPE_DATE_RELATIVE
	}
}

export const partnerAdminTable = {
	"Muni-noSort": "muniName",
	Program: "programName",
	'Account number': {
		field: "accountNumber",
		type: TYPE_NORMAL
	},
	Name: "firstName lastName",
	Email: "emailPrimary",
	Phone: "phone",
	"Zones-noSort": "sprinklerZones",
	'Redemption Status': {
		field: "redeemed",
		type: TYPE_REDEEMED
	},
	Updated: {
		field: "updated",
		type: TYPE_DATE_RELATIVE
	},
	"Address-noSort": "address",
	"City-noSort": "city",
	"Zip-noSort": "postalCode"
}

export const RachioAdminTable = {
	"Muni-noSort": "muniName",
	Program: "programName",
	'Account number': {
		field: "accountNumber",
		type: TYPE_NORMAL
	},
	Name: "firstName lastName",
	Email: "emailPrimary",
	Phone: "phone",
	"Zones-noSort": "sprinklerZones",
	'Redemption Status': {
		field: "redeemed",
		type: TYPE_REDEEMED
	},
	Updated: {
		field: "updated",
		type: TYPE_DATE_RELATIVE
	},
	"Address-noSort": "address",
	"City-noSort": "city",
	"Zip-noSort": "postalCode"
}
//