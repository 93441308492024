import React from 'react';
import { withRouter } from 'react-router';
import LayoutAuth from '../AuthShared';

class AuthLayout extends React.Component {
	constructor(props) {
		super(props);

		this.onAuthorized = this.onAuthorized.bind(this);
	}

	onAuthorized() {
		this.props.history.push('/admin');
	}

	render() {
		return (
			<LayoutAuth
				logo={'/img/rachio-logo-web.png'}
				onAuthorized={this.onAuthorized}
				component={this.props.component}
			/>
		);
	}
}

export default withRouter(AuthLayout);
