import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

class Filter extends React.Component {
	constructor(props) {
		super(props);

		this.handleInput = this.handleInput.bind(this);

		this.state = {
			defaultQuery: qs.parse(window.location.search).query,
		};
	}

	handleInput(event) {
		this.props.onChange({ query: event.target.value });
	}

	render() {
		return (
			<input
				className='filter__input'
				type='text'
				placeholder='Search'
				defaultValue={this.state.defaultQuery}
				onChange={this.handleInput}
				style={this.props.style}
			/>
		);
	}
}

Filter.propTypes = {
	onChange: PropTypes.func.isRequired,
};

export default Filter;
