import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  title,
  body
}) => (
  <div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={body}>
      <DialogTitle id={title}>{title}</DialogTitle>
      {body && <DialogContent>
        <DialogContentText id={body}>
          {body}
        </DialogContentText>
      </DialogContent>}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {'Cancel'}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
)

export default ConfirmationDialog