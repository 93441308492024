import React from "react";

import { Split } from "../../../components/ItemView";
import { ItemViewLayout } from "../../../components/ItemLayouts";
import { getPartner } from "../../../lib/api";
import SharedAccess from "../../../components/SharedAccess";

class PartnersPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			partner: null
		};
	}

	async componentDidMount() {
		const partner = await getPartner(this.props.match.params.partnerId);

		this.setState({
			partner
		});
	}

	render() {
		const { partner } = this.state;

		if (!partner) return null;

		return (
			<ItemViewLayout itemType="Partner" itemName={partner.name}>
				<div className="split">
					<div className="split__item">
						<Split
							item={partner}
							tables={
								[
									"Information",
									{
										"Partner ID": "id",
										Name: "name",
										Enabled: "enabled",
										"Time Zone": "timeZone",
										Created: "created",
										Updated: "updated",
										Latitude: "geoPoint.latitude",
										Longitude: "geoPoint.longitude",
										Zip: "zip",
										Website: "website"
									}
								]
							}
						/>
					</div>
					<div className="split__item">
						<SharedAccess
							partnerId={this.props.match.params.partnerId}
							partnerName={partner.name}/>
					</div>
				</div>
			</ItemViewLayout>
		);
	}
}

export default PartnersPage;
