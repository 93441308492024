import React from "react";

import StatsBar from "../../components/StatsBar";
import { getPrograms } from "../../lib/api";

class Dashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			totalResidentsCount: 0,
			totalResidentsRedeemed: 0,
			totalActivePrograms: 0
		};
	}

	async componentDidMount() {
		const programs = await getPrograms();
		
		if (programs) {
			const totalResidentsCount = programs.reduce(
				(sum, program) => sum + parseInt(program.residentsCount, 10),
				0
			);
			const totalResidentsRedeemed = programs.reduce(
				(sum, program) => sum + parseInt(program.residentsRedeemedCount, 10),
				0
			);
			const totalActivePrograms = programs.reduce(
				(sum, program) => sum + (program.status === "ACTIVE" ? 1 : 0),
				0
			);
	
			this.setState({
				totalResidentsCount,
				totalResidentsRedeemed,
				totalActivePrograms
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="container--full">
					<div className="block--sm">
						<div className="panel">
							<StatsBar
								stats={[
									{
										text: "Active Programs",
										stat: this.state.totalActivePrograms
									},
									{
										text: "Eligible Accounts",
										stat: this.state.totalResidentsCount
									},
									{
										text: "Residents Redeemed",
										stat: this.state.totalResidentsRedeemed
									}
								]}
							/>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Dashboard;
