/* eslint-disable no-var */

/**
 * creates csv data file
 *
 * @param {Array} items - array of objects
 * @param {string} filename - name of download file
 * @returns {string} - string of comma seperated column headers and column values
 */
export const createCSV = (items, filename) => {

	const csvArray = (items) => {
		const flattener = arrayToFlatten => {
			const array = []
			arrayToFlatten.forEach(item => {
				array.push(Object.assign(
					{},
					...function _flatten(o) {
						return [].concat(...Object.keys(o)
							.map(k =>
								typeof o[k] === 'object' ?
									_flatten(o[k]) :
									({ [k]: o[k] })
							)
						);
					}(item)
				))
			})
			return array
		}

		const headers = Object.keys(flattener(items.slice(0, 9)).reduce((acc, item) => {
			Object.keys(item).forEach(key => acc[key] = true)
			return acc
		}, {}))

		const values = flattener(items).map(item => {
			return headers.map(headerKey => {
				return item[headerKey] || ''
			})
		})

		return [headers, ...values]
	}

	const exportObject = csvArray(items)

	exportToCsv(filename, exportObject);
};

function exportToCsv(filename, rows) {
	var processRow = function (row) {
		var finalVal = "";
		for (var j = 0; j < row.length; j++) {
			var innerValue = row[j] === null ? "" : row[j].toString();
			if (row[j] instanceof Date) {
				innerValue = row[j].toLocaleString();
			}
			var result = innerValue.replace(/#/g, "").replace(/"/g, '""');
			if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
			if (j > 0) finalVal += ",";
			finalVal += result;
		}
		return finalVal + "\n";
	};

	var csvFile = "";
	for (var i = 0; i < rows.length; i++) {
		csvFile += processRow(rows[i]);
	}

	var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename);
	} else {
		var link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}
