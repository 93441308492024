/**
 * Form component
 *
 * Handles the boilerplate of many forms:
 * - Common UI elements - Loading state, success and error message
 * - Tracks form field values via the React Context API
 *
 * @example
 * function submitHandler(formContext) {
 *   const formData = formContext.values;
 * }
 * <Form
 *   onSubmit={submitHandler}
 *   buttonText='Submit My Form'
 *   buttonLoadingText='Submitting Form...'
 *   successMessage: 'Submission Successful!
 * >
 *   <Input
 *     name='firstName'
 *     label='First Name'
 *   />
 * </Form>
 *
 * @module form
 */

import React from "react";
import Joi from "joi-browser";
import PropTypes from "prop-types";

import itemLib from "../../utils/itemshared";
import {
	resultValidationError,
	responseValidationError
} from "../../utils/validation";
import { ErrorMessage, SuccessMessage } from "./Elements";

export * from "./Elements";
export const FormContext = React.createContext();

export class Form extends React.Component {
	constructor(props) {
		super(props);

		// Used to scroll to a success or error message after form submission
		this.formRef = React.createRef();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.setValue = this.setValue.bind(this);

		this.state = {
			submitted: false,
			loading: false,
			errorMessage: "",
			context: {
				values: {},
				setValue: this.setValue
			}
		};
	}

	setValue(name, value) {
		this.setState(prevState => ({
			context: {
				...prevState.context,
				values: itemLib.setObjectProperty(prevState.context.values, name, value)
			}
		}));
	}

	scrollToTop() {
		window.scrollTo({
			top: this.formRef.current.offsetTop - 200,
			behavior: "smooth"
		});
	}

	/**
	 * Handle form submission
	 *
	 * Process:
	 *   Validate fields against Joi schema (optional)
	 *   Execute the onSubmit prop
	 *   Handle response of onSubmit
	 *
	 * @param {object} event
	 * @param {object} formContext
	 */
	async handleSubmit(event) {
		event.preventDefault();

		this.setState({
			loading: true
		});

		const stateUpdates = {
			submitted: true,
			loading: false,
			errorMessage: null
		};

		// If a schema prop incl. - validate for errors
		if (this.props.schema) {
			const result = Joi.validate(this.state.context.values, this.props.schema);

			if (result.error) {
				stateUpdates.errorMessage = resultValidationError(result);
				this.setState(stateUpdates);
				this.scrollToTop();
				return;
			}
		}

		const response =
			(await this.props.onSubmit(this.state.context).catch(() => ({
				error: true,
				message: "No user found with that username/password combination. Please contact your admin."
			}))) || {};

		if (response.redirecting) {
			// Stop execution, new page will load and this form will be unmounted
			return;
		}

		// Check for server error
		if (response.error || response.code) {
			stateUpdates.errorMessage = responseValidationError(response);
		}

		this.setState(stateUpdates);
		this.scrollToTop();
		if (this.props.postSubmitAction) {
			this.props.postSubmitAction()
		}
	}

	render() {
		return (
			<FormContext.Provider value={this.state.context}>
				<form ref={this.formRef} onSubmit={event => this.handleSubmit(event)}>
					{this.state.submitted && (
						<div className="block--md">
							{this.state.errorMessage ? (
								<ErrorMessage>{this.state.errorMessage}</ErrorMessage>
							) : (
								<SuccessMessage>{this.props.successMessage}</SuccessMessage>
							)}
						</div>
					)}

					{this.props.children}

					<button
						className={`button button--lg ${
							this.props.buttonFull ? "button--full" : ""
						}`}
					>
						{this.state.loading
							? this.props.buttonLoadingText
							: this.props.buttonText}
					</button>
				</form>
			</FormContext.Provider>
		);
	}
}

Form.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	buttonText: PropTypes.string.isRequired,
	buttonLoadingText: PropTypes.string.isRequired,
	buttonFull: PropTypes.bool,
	successMessage: PropTypes.string
};

Form.defaultProps = {
	buttonFull: false,
	successMessage: "Your submission was successful!"
};

export default Form;
