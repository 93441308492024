import React from "react";
import ItemView from "../../../components/ItemView";
import { ItemViewLayout } from "../../../components/ItemLayouts";

import { getResident } from "../../../lib/api";
import { formatResidentAndDevices, formatResidentViewTable } from "../../../utils/device";
import { residentTable } from "./table";

class ResidentsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			resident: null
		};
	}

	async componentDidMount() {
		const resident = await getResident(this.props.match.params.residentId);

		this.setState({
			resident
		});
	}

	render() {
		const { resident } = this.state;

		if (!resident) return null;

		return (
			<ItemViewLayout
				isAdmin={this.props.isAdmin}
				showCreateButton={false}
				itemType="Resident"
				itemName={resident.emailPrimary}>
				<ItemView
					itemType="Resident"
					item={formatResidentAndDevices(resident)}
					tables={formatResidentViewTable(resident.devices, residentTable)}
				/>
			</ItemViewLayout>
		);
	}
}

export default ResidentsPage;
