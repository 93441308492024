import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const StatsBar = props => (
	<div className='flex text-center'>
		{props.stats.map(({ text, stat }) => (
			<div key={`${text}-${stat}`} className='flex__item'>
				<h2 className='statsbar__stat'>{stat}</h2>
				<p className='statsbar__stat-text light'>{text}</p>
			</div>
		))}
	</div>
);

StatsBar.propTypes = {
	stats: PropTypes.array.isRequired,
};

export default StatsBar;
