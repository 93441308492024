import React from "react";
import { Switch } from "react-router";
import { BrowserRouter, Redirect } from "react-router-dom";
import AnalyticsTracker from "./components/AnalyticsTracker";

import DefaultLayout from "./components/Layouts/Portal";
import AuthLayout from "./components/Layouts/Auth";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Dashboard from "./pages/Dashboard";
import PartnersList from "./pages/Partners/List";
import PartnersCreate from "./pages/Partners/Create";
import PartnersUpdate from "./pages/Partners/Update";
import PartnersView from "./pages/Partners/View";
import ProgramsList from "./pages/Programs/List";
import ProgramsCreate from "./pages/Programs/Create";
import ProgramsUpdate from "./pages/Programs/Update";
import ProgramsView from "./pages/Programs/View";
import ResidentsList from "./pages/Residents/List";
import ResidentsUpdate from "./pages/Residents/Update";
import ResidentsView from "./pages/Residents/View";
import ResidentsCreate from "./pages/Residents/Create";
import DevicesList from "./pages/Devices/List";

const Routes = props => (
	<BrowserRouter {...props}>
		<div>
			{process.env.NODE_ENV === "production" && <AnalyticsTracker GAToken={"UA-41010688-1"} />}
			<Switch>
				<AuthLayout exact path="/" component={Login} />
				<AuthLayout exact path="/reset" component={Reset} />
				<DefaultLayout exact adminRoute path="/admin/partners" component={PartnersList}/>
				<DefaultLayout exact adminRoute path="/admin/partners/create" component={PartnersCreate} />
				<DefaultLayout exact adminRoute path="/admin/partners/:partnerId/edit" component={PartnersUpdate} />
				<DefaultLayout exact adminRoute path="/admin/partners/:partnerId" component={PartnersView} />
				<DefaultLayout exact adminRoute path="/admin/programs/create" component={ProgramsCreate} />
				<DefaultLayout exact adminRoute path="/admin/programs/:programId/edit" component={ProgramsUpdate} />
				<DefaultLayout exact adminRoute path="/admin/residents/create" component={ResidentsCreate} />
				<DefaultLayout exact adminRoute path="/admin/residents/:residentId/edit" component={ResidentsUpdate} />
				<DefaultLayout exact adminRoute path="/admin/devices" component={DevicesList} />
				<DefaultLayout exact path="/admin" component={Dashboard} />
				<DefaultLayout exact path="/admin/programs" component={ProgramsList} />
				<DefaultLayout exact path="/admin/programs/:programId" component={ProgramsView} />
				<DefaultLayout exact path="/admin/residents" component={ResidentsList} />
				<DefaultLayout exact path="/admin/residents/:residentId" component={ResidentsView} />
				<Redirect exact path="*" to="/admin" />
			</Switch>
		</div>
	</BrowserRouter>
)

export default Routes;
