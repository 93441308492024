/**
 * Adds devices to table view for resident/view if resident has device(s)
 *
 * @param {object} res - resident object
 * @returns {object} resident object containing devices formatted for display in resident/view
 */
export const formatResidentAndDevices = res => {
	// Change redeemed statud from boolean to string ('yes'/'no')
	const resident = {
		...res,
		redeemed: res.redeemed ? 'Yes': 'No',
		// installationType: res.installationType && res.installationType.installationType ? res.installationType.installationType : 'DIY',
		// installLocation: res.installLocation && res.installLocation.installLocation ? res.installLocation.installLocation :'INDOORS'
	}

	// if resident has no devices, return resident as is.
	// if (!res.devices || (!res.devices && res.devices.length)) {
	// 	return resident;
	// }

	return {
		...resident,
		// ...resident.devices.reduce((devicesObj, device, i) => {
		// 	devicesObj[`devices${i}`] = {};
		// 	Object.keys(device).forEach(key => {
		// 		devicesObj[`devices${i}`][key] = device[key];
		// 	});
		// 	return devicesObj;
		// }, {})
	};
};

/**
 * Adds devices to table view for resident/view if resident has device(s)
 *
 * @param {Array} devices - Array of devices for resident
 * @param {Array} table - Resident view table array
 * @returns {Array} new table consisting of devices object
 */
export const formatResidentViewTable = (devices, table) => {
	// if resident has no devices or if they already exist in table, return table as is.

	// if (!devices.length || table[0].indexOf("Devices") > -1) {
	// 	return table;
	// }
	// devices.forEach((device, i) => {
	// 	table[0].push(`Device${i === 0 ? "s" : i + 1}`); // will label 'devices' if only one exists and 'device2' ...3, etc for all additional.
	// 	table[0].push({
	// 		id: `devices${i}.id`,
	// 		Type: `devices${i}.type`,
	// 		Created: `devices${i}.created`,
	// 		Updated: `devices${i}.created`,
	// 		Name: `devices${i}.name`,
	// 		latitude: `devices${i}.geoPoint.latitude`,
	// 		longitude: `devices${i}.geoPoint.longitude`,
	// 		"location ID": `devices${i}.locationId`
	// 	});
	// });
	return table;
};
