/**
 * Collection of functions for interacting with other apis
 *
 * Functions in this file should only define an endpoint and and how to parse
 * the recieved data
 *
 * Get single calls will return an object
 * Get all calls will return an array of objects
 * Update will return an object
 * Delete will return null
 *
 * @module api
 */

import API from "../utils/api";

import store from "../store";

const getBearerToken = () => store.getState().authentication.auth.access_token;
const api = API({
	url: process.env.REACT_APP_RACHIO_URL,
	authUrl: process.env.REACT_APP_RACHIO_AUTH_URL,
	clientId: process.env.REACT_APP_RACHIO_AUTH_CLIENT_ID,
	clientSecret: process.env.REACT_APP_RACHIO_AUTH_CLIENT_SECRET,
	getBearerToken
});

/**
 * Api functions
 */

/**
 * @param {object} credentials
 * @param {string} credentials.email
 * @param {string} credentials.password
 */
export const login = async credentials => api.oAuthPost("/token", credentials);

/**
 * Get the rachio user from their user id and access token
 * @param {string} access_token
 * @param {string} user_id
 */
export const getUser = async ({ user_id, access_token }) => api.basicGet(`/user/getUser/${user_id}`, { bearerToken: access_token });

/**
 * @param {string} id
 * @see https://github.com/Rachio/proto/blob/master/muni_acount.proto
 */
export async function getPartner(id) {
	const response = await api.authedGet(`/muni/${id}`);
	return response.muni;
}

export async function getPartners() {
	const response = await api.authedGet("/muni/portal/list");

	if (response instanceof Error) return response;

	return response.muni.map(partner =>
		Object.assign(partner, {
			residentsCount: parseInt(partner.residentsCount, 10),
			residentsRedeemedCount: parseInt(partner.residentsRedeemedCount, 10),
			controllersDistributedCount: parseInt(
				partner.controllersDistributedCount,
				10
			)
		})
	);
}

/**
 * @param {object} partner
 * @param {string} partner.name
 */
export async function createPartner(partner) {
	return api.authedPost("/muni/create", partner);
}

/**
 * @param {object} body
 * @param {string} body.id - id of user to update
 * @param {*} body.rest - fields to be updated
 */
export async function updatePartner(body) {
	return api.authedPut(`/muni/${body.id}`, body);
}

/**
 * @param {string} id
 */
export async function getProgram(id) {
	const response = await api.authedGet(`/muni/program/${id}`);
	return response.program;
}

export async function getPrograms() {
	const response = await api.authedGet("/muni/program/list/useChildren");

	if (response.programs) {
		return response.programs.map(program =>
			Object.assign(program, {
				distributionLimit: parseInt(program.distributionLimit, 10),
				residentsCount: parseInt(program.residentsCount, 10),
				residentsRedeemedCount: parseInt(program.residentsRedeemedCount, 10),
				unitsLeftCount: parseInt(program.unitsLeftCount, 10)
			})
		);
	}

}

/**
 * @param {object} program
 * @see https://github.com/Rachio/proto/blob/master/muni_program.proto
 */
export async function createProgram(program) {
	return api.authedPost("/muni/program/create", program);
}

/**
 * @param {object} body
 * @param {string} body.id - id of user to update
 * @param {*} body.rest - fields to be updated
 */
export async function updateProgram(body) {
	return api.authedPut(`/muni/program/${body.id}`, body);
}

/**
 * @param {string} id - programId to be delted
 */
export async function deleteProgram(id) {
	return api.authedDelete(`/muni/program/${id}`);
}

/**
 * @param {object} body
 */
export async function getDevices(body) {
	return api.authedPost("/muni/device/list", body);
}

/**
 * @param {string} id
 * @see https://github.com/Rachio/proto/blob/master/muni_resident.proto
 */
export async function getResident(id) {
	const response = await api.authedGet(`/muni/resident/${id}`);
	return response.resident;
}

export async function getResidents(body) {
	return api.authedPost("/muni/resident/list", body);
}

export async function listUsers(id) {
	return api.authedGet(`/muni/portal/${id}/user/list`);
}

// Endpoint not available yet
export async function removeUser(partnerId, userId) {
	return api.authedDelete(`/muni/portal/${partnerId}/user/remove`, {userId, partnerId});
}

export async function resetPassword(body) {
	return api.basicPost('/user/SendResetPasswordEmail', body)
}

export async function addMuniUser(body) {
	return api.authedPost(`/muni/portal/${body.partnerId}/user/add`, body);
}

/**
 * @param {object} resident
 */
export async function createResident(resident) {
	return api.authedPost("/muni/resident/create", { residents: [resident] });
}

/**
 * @param {array} residents
 */
export async function createResidents(residents) {
	return api.authedPost("/muni/resident/create", { residents });
}
/**
 * @param {object} body
 */
export async function uploadSerials(body) {
	return api.authedPut("/muni/program/link", body);
}

/**
 * @param {object} body
 */
export async function devicesToResident(body) {
	return api.authedPut("/muni/resident/link", body);
}

/**
 * @param {object} body
 * @param {string} body.id - id of user to update
 * @param {*} body.rest - fields to be updated
 */
export async function updateResident(body) {
	return api.authedPut(`/muni/resident/${body.id}`, body);
}

/**
 * @param {object} body
 * @param {string} id - id of user to update
 */
export async function deleteResident(id, body) {
	return api.authedDelete(`/muni/resident/${id}`, body);
}

/**
 * @param {string} id - id of partner to delete
 */
export async function deletePartner(id) {
	return api.authedDelete(`/muni/${id}`);
}

/**
 * @see https://github.com/Rachio/proto/blob/master/device_service.proto
 */
export async function getController(id) {
	return api.authedGet(`/muni/getDevice/${id}`);
}
