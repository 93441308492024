import React from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { withRouter } from "react-router";

import "./style.css";

const PER_PAGE = 100;

class Pagination extends React.Component {
	constructor(props) {
		super(props);

		this.handlePageChange = this.handlePageChange.bind(this);
	}

	handlePageChange(pageChange) {
		const page = this.props.page + pageChange;
		const currentQuery = qs.parse(window.location.search);
		const updatedQuery = Object.assign({}, currentQuery, { page });

		if (page < 0) return;

		this.props.history.push({
			search: qs.stringify(updatedQuery)
		});

		this.props.onPageChange(page);
	}

	render() {
		const { page, itemCount } = this.props;

		const prevPageExists = page > 0;
		const nextPageExists = itemCount >= PER_PAGE;
		const itemRangeEnd = page * PER_PAGE;
		const itemRange = `${itemRangeEnd + 1} - ${itemRangeEnd + 100}`;

		return (
			<div className="pagination">
				<span className="list--vertical">
					<button
						className="list__item button"
						disabled={!prevPageExists}
						onClick={this.handlePageChange.bind(null, -1)}
					>
						Prev
					</button>
					<button
						className="list__item button"
						disabled={!nextPageExists}
						onClick={this.handlePageChange.bind(null, 1)}
					>
						Next
					</button>

					<span className="list__item light">{itemRange}</span>
				</span>
			</div>
		);
	}
}

Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	itemCount: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired
};

export default withRouter(Pagination);
